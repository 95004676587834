/* eslint-disable import/extensions */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  FormGroup,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { ValidationError } from 'yup';

import { Form } from '@unform/web';
import {
  updateDelivery,
  updateClientAddress,
  saveClientAddress,
  setTemporaryStore,
  updateVehicleForDelivery,
  createCart,
  updateCartInformations,
} from '~/store/modules/cart/actions';

import api from '~/services/api';
import AddressForm from '~/components/AddressForm';
import SchemaValidator from '~/validators/form/sale_delivery_form';
import { deliveryFleets } from '~/util/deliveryFleets';

import Loading from '~/components/Loading';
import InputSelect from '~/components/Inputs/InputSelect';
import { getRole } from '~/util/getRole';
import DefaultStoreAlert from '../DefaultStoreAlert';

const DeliveryForm = ({ targetUserId }) => {
  const dispatch = useDispatch();

  const formRef = useRef(null);
  const formStoreRef = useRef(null);
  const formVehicleRef = useRef(null);
  const deliveryObservationRef = useRef(null);

  const userId = useSelector(({ user }) => user.profile.id);
  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const [handleDisabled, setHandleDisabled] = useState(false);

  const vehicle = useSelector(({ cart }) => cart.vehicle);

  const deliveryTag = useSelector(({ cart }) => cart.deliveryFleet);

  const [deliveryServices, setDeliveryServices] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showDefaultStoreAlert, setShowDefaultStoreAlert] = useState(false);

  const selectedStore = useSelector(({ cart }) => cart.selectedStore);

  const userStore = useSelector(({ user }) => ({
    value: user?.profile?.stock?.id,
    label: user?.profile?.stock?.fantasy_name,
    has_mapp: user?.profile?.stock?.has_mapp,
  }));

  // if user is store manager we dont pull the data from the profile, we load from a request
  const [managedStores, setManagedStores] = useState([]);

  const vehiclesAvailable = [
    { value: 'M', label: 'Moto' },
    ...(deliveryTag && deliveryTag === 'UBER'
      ? [{ value: 'C', label: 'Carro' }]
      : []),
  ];
  const [storesAvailable, setStoresAvailable] = useState([]);

  const storeSelectorStyles = {
    menu: base => ({
      ...base,
      maxHeight: 250,
      width: '100%',
    }),
    menuList: base => ({
      ...base,
      maxHeight: 250,
      width: '100%',
    }),
  };

  const setStoreSelectable = option => {
    const store = managedStores.find(p => p.value === option.value);

    if (!store) return;

    const selectStore = {
      value: store.value,
      label: store.label,
    };

    dispatch(setTemporaryStore(selectStore));
  };

  const done = useSelector(({ cart }) => cart.done);
  const cartState = useSelector(({ cart }) => cart.cart);

  const [deliveryObservation, setDeliveryObservation] = useState();
  const is_suitcase_delivery = useSelector(
    ({ cart }) => cart.cart?.is_suitcase_delivery || cart.is_suitcase_delivery
  );

  const [deliveryType, setDeliveryType] = useState(null);

  useEffect(() => {
    let defaultDeliveryType;
    if (cartState?.is_for_delivery && !is_suitcase_delivery) {
      defaultDeliveryType = 'entrega';
      dispatch(
        updateDelivery({
          value: true,
          is_suitcase_delivery: false,
        })
      );
    }
    if (cartState?.is_for_delivery && is_suitcase_delivery) {
      defaultDeliveryType = 'malinha';
      dispatch(
        updateDelivery({
          value: true,
          is_suitcase_delivery: true,
        })
      );
    }
    if (!cartState?.is_for_delivery) {
      defaultDeliveryType = 'retirada';
      dispatch(
        updateDelivery({
          tag: 'RETIRAR',
          value: false,
          is_suitcase_delivery: false,
        })
      );
    }

    if (!cartState) return;

    setDeliveryType(defaultDeliveryType);
    // eslint-disable-next-line
  }, []);

  const client = useSelector(({ cart }) => cart.client);

  const chosenRegion = useSelector(({ cart }) => cart.chosenRegion);

  const isForDelivery = deliveryType !== 'retirada';

  const [isFormValid, setIsFormValid] = useState(false);
  const [iDontKnowCEP, setIDontKnowCEP] = useState(null);

  useEffect(() => {
    if (userStore?.value && !selectedStore) {
      dispatch(setTemporaryStore(userStore));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getDeliveryServices = async () => {
      try {
        setLoading(true);
        const availableDeliveriesRequest = api.get(
          `/utils/available-delivery-services`
        );
        const availableStoresRequest = api.get(
          `/${role}/users/${targetUserId || userId}/managed_stores`
        );

        const [
          {
            data: { delivery_services },
          },
          {
            data: { managed_stores },
          },
        ] = await Promise.all([
          availableDeliveriesRequest,
          availableStoresRequest,
        ]);

        setManagedStores(
          managed_stores.map(s => ({
            value: s.id,
            label: s.fantasy_name,
            has_mapp: s.has_mapp,
          }))
        );

        const getDeliveryServicesAvailable = deliveryFleets.filter(
          type => delivery_services.includes(type.tag) || type.tag === 'RETIRAR'
        );

        const getDeliveryServicesAvailableWithSubscriber =
          getDeliveryServicesAvailable.map(type =>
            type.tag === 'PROPRIA'
              ? {
                  ...type,
                  label: `Alternativa`,
                  value: type.tag,
                  is_for_delivery: type.value,
                }
              : { ...type, value: type.tag, is_for_delivery: type.value }
          );

        setDeliveryServices(getDeliveryServicesAvailableWithSubscriber);
        setLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Erro ao carregar os serviços de entrega!');
        }
        setLoading(false);
      }
    };

    getDeliveryServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      deliveryTag === 'BEE' ||
      deliveryTag === 'MOTTU' ||
      deliveryTag === 'UBER' ||
      deliveryTag === 'RETIRAR'
    ) {
      setStoresAvailable(_ => managedStores);
    }

    if (deliveryTag === 'PROPRIA') {
      if (!selectedStore?.has_mapp) {
        dispatch(setTemporaryStore(''));
      }
      setStoresAvailable(_ => managedStores.filter(s => s.has_mapp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryTag, managedStores]);

  const updateCartAction = useCallback(() => {
    if (!cartState?.id) {
      return createCart({
        setHandleDisabled,
        delivery_observation: deliveryObservation,
        target_user_id: targetUserId,
      });
    }
    return updateCartInformations({
      setHandleDisabled,
      is_suitcase_delivery: deliveryType === 'malinha',
      delivery_observation: deliveryObservation,
      target_user_id: targetUserId,
    });
  }, [cartState?.id, deliveryObservation, deliveryType, targetUserId]);

  useEffect(() => {
    if (!isFormValid) {
      return;
    }

    dispatch(updateCartAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid]);

  const btnLabel = useMemo(() => {
    if (cartState && cartState?.id) {
      return 'Atualizar Carrinho';
    }
    return 'Avançar';
  }, [cartState]);

  const handleSubmitAddress = async data => {
    setHandleDisabled(true);
    try {
      formRef.current.setErrors({});
      await SchemaValidator.validate(data, {
        abortEarly: false,
      });

      setIsFormValid(true);

      data = {
        ...data,
        delivery_additional_data: chosenRegion
          ? { region_id: chosenRegion.id, name: chosenRegion.name }
          : null,
      };

      dispatch(
        cartState?.id
          ? updateClientAddress(data)
          : saveClientAddress({ address: data })
      );
    } catch (err) {
      const validationErrors = {};

      if (err instanceof ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });

        if (iDontKnowCEP) {
          validationErrors.cep = undefined;
        }

        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }
    } finally {
      setHandleDisabled(false);
    }
  };

  const handleSubmit = async _ => {
    setHandleDisabled(true);

    if (!selectedStore) {
      toast.error('Selecione uma loja para continuar!');
      formStoreRef.current.setFieldError('store_id', 'Selecione uma loja');
      setHandleDisabled(false);
      return;
    }

    if (!vehicle && deliveryTag === 'BEE') {
      toast.error('Atenção, existe campos que precisam ser preenchidos!');
      setHandleDisabled(false);
      return;
    }

    if (deliveryTag === 'RETIRAR') {
      dispatch(updateCartAction());
      return;
    }

    formRef.current.submitForm();
  };

  const subscriberId = localStorage.getItem('@VIDDY:X-Server');
  const subscriberViddyComercial = '504c2f33-f3af-411c-b6f4-c9c483f8b745';

  // auto resize of delivery observation textarea
  useEffect(() => {
    if (!deliveryObservationRef.current) return;

    const deliveryObservationInput = deliveryObservationRef.current;

    deliveryObservationInput.style.height = '0px';
    deliveryObservationInput.style.height = `${deliveryObservationInput.scrollHeight}px`;
  }, [deliveryObservation]);

  return (
    <>
      <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-3">
            Tipo de Entrega/Coleta
          </Row>
          {loading && (
            <Loading
              customClassName="loadingpix"
              message="Consultando os serviços de entrega!"
            />
          )}
          {!loading && (
            <Row className="w-100 mx-0">
              <Nav className="w-100" pills>
                <Row className="w-100 mx-0">
                  <Col className="px-0 pr-lg-2" xs={12} lg={4}>
                    <NavItem>
                      <NavLink
                        className={`new-layout-nav-pill card d-flex flex-column align-items-center h-fit-content w-100 ${
                          deliveryType === 'entrega' ? 'checked' : 'unchecked'
                        } font-size-14`}
                        onClick={() => {
                          setDeliveryType('entrega');
                          dispatch(
                            updateDelivery({
                              value: true,
                              is_suitcase_delivery: false,
                            })
                          );
                        }}
                        to="#"
                      >
                        <span className="text-nowrap">Entrega</span>
                      </NavLink>
                    </NavItem>
                  </Col>
                  {subscriberId !== subscriberViddyComercial && (
                    <Col className="px-0 px-lg-2" xs={12} lg={4}>
                      <NavItem>
                        <NavLink
                          className={`new-layout-nav-pill card d-flex flex-column align-items-center h-fit-content w-100 ${
                            deliveryType === 'retirada'
                              ? 'checked'
                              : 'unchecked'
                          } font-size-14`}
                          onClick={() => {
                            setDeliveryType('retirada');
                            dispatch(
                              updateDelivery({
                                tag: 'RETIRAR',
                                value: false,
                                is_suitcase_delivery: false,
                              })
                            );
                          }}
                          to="#"
                        >
                          <span className="text-nowrap">Retirada Em Loja</span>
                        </NavLink>
                      </NavItem>
                    </Col>
                  )}
                  {subscriberId !== subscriberViddyComercial && (
                    <Col className="px-0 pl-lg-2" xs={12} lg={4}>
                      <NavItem>
                        <NavLink
                          className={`new-layout-nav-pill card d-flex flex-column align-items-center h-fit-content w-100 ${
                            deliveryType === 'malinha' ? 'checked' : 'unchecked'
                          } font-size-14`}
                          onClick={() => {
                            setDeliveryType('malinha');
                            dispatch(
                              updateDelivery({
                                value: true,
                                is_suitcase_delivery: true,
                              })
                            );
                          }}
                          to="#"
                        >
                          <span className="text-nowrap">Malinha</span>
                        </NavLink>
                      </NavItem>
                    </Col>
                  )}
                </Row>
              </Nav>
            </Row>
          )}
        </CardBody>
      </Card>
      {deliveryType === 'malinha' ? (
        <Card className="card new-layout">
          <CardBody className="card-body new-layout">
            <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-2">
              Descrição dos produtos enviados
            </Row>
            <p>
              Anote as informações necessárias para ajudar a conferir quando os
              itens forem coletados.
            </p>

            <textarea
              name="description"
              rows={1}
              style={{
                outline: 'none',
                color: '#495057 !important',
                borderRadius: '0.15rem',
                fontSize: '14px',
                width: '100%',
                border: '1px solid #b9bbc6',
                padding: '0.8rem 0.6rem',
              }}
              ref={deliveryObservationRef}
              onChange={e => {
                setDeliveryObservation(e.target.value);
              }}
              value={deliveryObservation}
            />
          </CardBody>
        </Card>
      ) : null}
      {deliveryType && (
        <Card className="card new-layout">
          <CardBody className="card-body new-layout">
            <Row className="px-2">
              <Col lg={6} className="px-0 pr-lg-2">
                <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-2">
                  {isForDelivery ? 'Loja para coleta' : 'Loja para retirada'}
                </Row>
                <Form ref={formStoreRef}>
                  <Row className="w-100 mx-0 mt-3 mb-4">
                    <FormGroup className="w-100">
                      <InputSelect
                        options={storesAvailable}
                        name="store_id"
                        onChange={setStoreSelectable}
                        isSearchable
                        placeholder="Nome da loja"
                        type="text"
                        value={selectedStore}
                        loadingMessage={() => 'Buscando lojas...'}
                        styles={storeSelectorStyles}
                      />
                    </FormGroup>
                  </Row>
                </Form>
              </Col>
              {deliveryType !== 'retirada' && (
                <Col lg={6} className="px-0 pr-lg-2">
                  <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-2">
                    Transportadora
                  </Row>
                  <Form ref={formVehicleRef}>
                    <Row className="w-100 mx-0 mt-3 mb-4">
                      <FormGroup className="w-100">
                        <InputSelect
                          name="delivery_service"
                          options={deliveryServices}
                          onChange={e => {
                            dispatch(
                              updateDelivery({
                                tag: e.tag,
                                value: e.is_for_delivery,
                              })
                            );
                          }}
                          value={deliveryServices.find(
                            deliveryService =>
                              deliveryService.tag === deliveryTag
                          )}
                          placeholder="Selecione a Transportadora"
                          isSearchable
                          type="text"
                          styles={storeSelectorStyles}
                        />
                      </FormGroup>
                    </Row>
                  </Form>
                </Col>
              )}
            </Row>
            <Row className="px-2">
              {isForDelivery &&
                (deliveryTag === 'BEE' || deliveryTag === 'UBER') && (
                  <Col lg={6} className="px-0 pr-lg-2">
                    <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-2">
                      Tipo de veículo
                    </Row>
                    <Form ref={formVehicleRef}>
                      <Row className="w-100 mx-0 mt-3 mb-4">
                        <FormGroup className="w-100">
                          <InputSelect
                            name="vehicle"
                            options={vehiclesAvailable}
                            onChange={e => {
                              dispatch(updateVehicleForDelivery(e.value));
                            }}
                            placeholder="Tipo de veículo"
                            isSearchable
                            type="text"
                            value={vehiclesAvailable.find(
                              ({ value }) => value === vehicle
                            )}
                            loadingMessage={() => 'Buscando veículos...'}
                            styles={storeSelectorStyles}
                          />
                        </FormGroup>
                      </Row>
                    </Form>
                  </Col>
                )}
            </Row>
            {deliveryType !== 'retirada' && deliveryTag && isForDelivery && (
              <>
                <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-1">
                  Endereço de entrega
                </Row>
                <Row className="w-100 mx-0 mb-4">
                  <AddressForm
                    formRef={formRef}
                    initialData={client?.address}
                    handleSubmit={handleSubmitAddress}
                    submitBtnDisabled={done}
                    setSubmitBtnDisabled={setHandleDisabled}
                    sn={client?.address?.number === 'sn'}
                    iDontKnowCEP={iDontKnowCEP}
                    setIDontKnowCEP={setIDontKnowCEP}
                    hideBeforeCEP
                  />
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      )}

      <Row className="w-100 mx-0 mb-4">
        <Col className="w-100 px-0">
          <Button
            color="primary"
            size="lg"
            type="submit"
            className="float-right w-lg-25 new-layout-btn main"
            disabled={handleDisabled}
            onClick={
              userStore?.value === selectedStore?.value
                ? handleSubmit
                : () => setShowDefaultStoreAlert(true)
            }
          >
            {btnLabel}
          </Button>
        </Col>
      </Row>
      <DefaultStoreAlert
        setShow={setShowDefaultStoreAlert}
        show={showDefaultStoreAlert}
        submit={handleSubmit}
      />
    </>
  );
};

export default DeliveryForm;
