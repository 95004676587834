import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Collapse,
  Row,
} from 'reactstrap';
import IconButton from '~/components/IconButton';
import api from '~/services/api';
import { LG } from '~/util/breakpointWidths';
import { defineExpandedState } from '~/util/newLayoutHelpers';
import useRoles from '~/hooks/useRoles';

export default function ProductsListCard({
  products,
  cart,
  setProductsData,
  setDone,
  setSeparated,
}) {
  const location = useLocation();
  const windowSize = useSelector(({ layout }) => layout.windowSize);
  const { isConsultant } = useRoles();

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  useEffect(() => {
    setExpanded(defineExpandedState(windowSize, LG));
  }, [windowSize]);

  const confirm = async productId => {
    await api.patch(`/stockist/carts/${cart.id}/products/${productId}/confirm`);
    setProductsData(
      products.map(product =>
        product.id === productId ? { ...product, checked: true } : product
      )
    );
  };
  const disconfirm = async productId => {
    setDone(false);
    setSeparated(false);
    await api.patch(
      `/stockist/carts/${cart.id}/products/${productId}/disconfirm`
    );
    setProductsData(
      products.map(product =>
        product.id === productId ? { ...product, checked: false } : product
      )
    );
  };

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row
          className="w-100 mx-0 mb-lg-4 cursor-pointer"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Col className="font-size-16 font-weight-600 text-gray-600 px-0">
            <Row className="w-100 mx-0">
              <Col className="px-0" xs={10}>
                PRODUTOS
              </Col>
              <Col className="px-0 d-lg-none" xs={2} align="end">
                <IconButton
                  icon={`las la-${expanded ? 'minus' : 'plus'}`}
                  click={() => {
                    setExpanded(!expanded);
                  }}
                  color="gray-700"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          <Row className="w-100 mx-0 products-table-row header py-2 d-none d-lg-flex">
            <Col xs={1} className="font-size-13 font-weight-600 text-gray-700">
              #
            </Col>
            <Col xs={5} className="px-0">
              <Row className="w-100 mx-0">
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Referência
                </Col>
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Produto
                </Col>
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Presente
                </Col>
              </Row>
            </Col>
            <Col xs={1} className="font-size-13 font-weight-600 text-gray-700">
              Qtd
            </Col>
            <Col xs={5} className="px-0">
              <Row className="w-100 mx-0">
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Valor Unit.
                </Col>
                <Col className="font-size-13 font-weight-600 text-gray-700">
                  Valor Total
                </Col>
                {!isConsultant && (
                  <Col className="font-size-13 font-weight-600 text-gray-700">
                    Separado
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {products.map(product => (
            <div key={product.id} className="mt-4 mt-lg-0">
              <Row className="w-100 mx-0 products-table-row py-2 d-none d-lg-flex">
                <Col
                  xs={1}
                  className="font-size-14 font-weight-400 text-gray-700"
                >
                  {product.formatted_index}
                </Col>
                <Col xs={5} className="px-0 ">
                  <Row className="w-100 mx-0">
                    <Col className="font-size-14 font-weight-400 text-gray-700">
                      #{product.code}
                    </Col>
                    <Col className="font-size-14 font-weight-400 text-gray-700 text-truncate">
                      {product.name}
                    </Col>
                    <Col className="font-size-14 font-weight-400 text-gray-700">
                      {product.is_for_gift}
                      {/* (Cx. {product.pivot.gift_box_index}) */}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={1}
                  className="font-size-14 font-weight-400 text-gray-700 text-truncate"
                >
                  {product.formatted_qtd}
                </Col>
                <Col xs={5} className="px-0 ">
                  <Row className="w-100 mx-0">
                    <Col className="font-size-14 font-weight-400 text-gray-700">
                      {product.formatted_unit_value}
                    </Col>
                    <Col className="font-size-14 font-weight-400 text-gray-700 text-truncate">
                      {product.formatted_total_value}
                    </Col>
                    {!isConsultant && (
                      <Col className="font-size-14 font-weight-400 text-gray-700">
                        {location.pathname.includes('venda') ? (
                          product.separated
                        ) : (
                          <ButtonGroup className="z-index-0" size="sm">
                            <Button
                              disabled={cart?.status >= 3}
                              outline={!product.checked}
                              color="primary"
                              onClick={() => confirm(product.id)}
                            >
                              Sim
                            </Button>
                            <Button
                              disabled={cart?.status >= 3}
                              outline={product.checked}
                              color="primary"
                              onClick={() => disconfirm(product.id)}
                            >
                              Não
                            </Button>
                          </ButtonGroup>
                        )}
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <Card className="cart-details-product-mobile-card d-lg-none">
                <Col>
                  <Row className="font-size-12 font-weight-600 text-gray-700 mr-2">
                    #{product.formatted_index}
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Referência:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.code}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Produto:
                    </div>
                    <div
                      className="font-size-14 font-weight-400 text-gray-700 text-truncate"
                      style={{ maxWidth: '75%' }}
                    >
                      {product.name}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Presente:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.pivot.gift ? 'Sim' : 'Não'}
                      {/* (Cx. {product.pivot.gift_box_index}) */}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Qtd.:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.formatted_qtd}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Valor Unit.:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.formatted_unit_value}
                    </div>
                  </Row>
                  <Row className="d-flex flex-row align-items-baseline">
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Valor Total:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {product.formatted_total_value}
                    </div>
                  </Row>
                  <Row
                    className={`d-flex flex-row align-items-baseline ${
                      !location.pathname.includes('venda')
                        ? 'justify-content-between'
                        : ''
                    }`}
                  >
                    <div className="font-size-12 font-weight-600 text-gray-700 mr-2">
                      Separado:
                    </div>
                    <div className="font-size-14 font-weight-400 text-gray-700">
                      {location.pathname.includes('venda') ? (
                        product.separated
                      ) : (
                        <ButtonGroup className="z-index-0" size="sm">
                          <Button
                            disabled={cart?.status >= 3}
                            outline={!product.checked}
                            color="primary"
                            onClick={() => confirm(product.id)}
                          >
                            Sim
                          </Button>
                          <Button
                            disabled={cart?.status >= 3}
                            outline={product.checked}
                            color="primary"
                            onClick={() => disconfirm(product.id)}
                          >
                            Não
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>
                  </Row>
                </Col>
              </Card>
            </div>
          ))}
          <Row className="w-100 mx-0 my-4">
            <Col lg={3} />
            <Col
              lg={5}
              align="end"
              className="font-size-18 font-weight-400 text-gray-700"
            >
              Total sem frete e descontos
            </Col>
            <Col
              lg={3}
              align="end"
              className="font-size-18 font-weight-700 text-gray-700"
            >
              {cart.formatted_amount}
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    </Card>
  );
}
