import { useMemo, useRef, useState } from 'react';
// import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { ValidationError } from 'yup';
import { Form } from '@unform/web';
import PageHeader from '~/components/Common/PageHeader';
import Input from '~/components/Inputs/Input';
import history from '~/services/history';
import api from '~/services/api';
import { maskBuilder } from '~/util/maskTelephone';
import InputMask from '~/components/Inputs/InputMask';
import { useSelector } from 'react-redux';
import { getRole } from '~/util/getRole';
import { capitalize, formatWhatsappPhoneNumber } from '~/util/format';
import { isAndroid, isIOS } from '~/util/userAgent';

const AddCustomerContact = () => {
  const formRef = useRef(null);

  const profile = useSelector(({ user }) => user.profile);

  const role = useMemo(
    () =>
      getRole(profile.roles, [
        'administrator',
        'digital-consultant',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [profile]
  );

  const [name, setName] = useState();
  const [telephone, setTelephone] = useState();

  const handleValidSubmit = async _formData => {
    try {
      formRef.current.setErrors({});

      const { data } = await api.post(`/${role}/clients`, {
        name: capitalize(name),
        telephone,
        type: 2,
        user_id: profile.id,
        draft: true,
      });

      const href = `
        https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
          telephone
        )}&text=Ol%C3%A1%20${name}%2C%20aqui%20%C3%A9%20a%20${
        profile.name
      }%20vendedora%20da%20${
        profile.stock.fantasy_name
      }.%20Gostaria%20de%20confirmar%20a%20adi%C3%A7%C3%A3o%20dos%20seus%20dados%20na%20minha%20agenda%20de%20contatos.%0A%0AConfirme%20no%20link%3A%0A%0A${
        data.client.confirmation_url
      }
      `;

      if (isIOS() || isAndroid()) {
        setTimeout(() => window.open(href), 0);

        return;
      }

      const a = document.createElement('a');

      a.href = href;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';

      a.click();

      history.push(`/`);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (!err?.response?.data) return;

      Object.entries(err?.response?.data).forEach(([field_name, error]) =>
        formRef.current.setFieldError(field_name, error[0])
      );

      /* Object.values(err?.response?.data).forEach(
        ([error]) => toast.error(error, { autoClose: 5000 })
      ) */
    }
  };

  return (
    <div className="page-content">
      <Container className="container new-layout">
        <PageHeader
          pageTitle="ADICIONAR CONTATO"
          backTo="/carteira-de-clientes"
          responsivePosition="start"
          desktopPosition="center"
        />
        <Form onSubmit={handleValidSubmit} ref={formRef} noValidate>
          <Card className="card new-layout">
            <CardBody className="card-body new-layout">
              <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-4">
                Dados do Contato
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <FormGroup>
                    <Label>Nome</Label>
                    <Input
                      name="name"
                      placeholder="Nome"
                      type="text"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <FormGroup>
                    <Label>Número do Whatsapp</Label>
                    <InputMask
                      mask={maskBuilder(telephone)}
                      maskChar={null}
                      name="telephone"
                      placeholder="Whatsapp"
                      type="tel"
                      value={telephone}
                      onChange={e => setTelephone(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row className="w-100 mx-0 my-4">
            <Col align="end" className="px-0">
              <Button
                className="new-layout-btn main"
                color="primary"
                size="lg"
                type="submit"
              >
                Adicionar contato
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddCustomerContact;
