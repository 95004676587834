import { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Col, Row, Container, Button, Card, CardBody, Badge } from 'reactstrap';
import PageHeader from '~/components/Common/PageHeader';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import history from '~/services/history';
import { formatPrice } from '~/util/format';
import { getRole } from '~/util/getRole';
import api from '~/services/api';

const SplitReceiverCard = ({ receiver }) => {
  const serviceType = () => {
    if (receiver.service_type === 'delivery') return 'Entrega';

    return 'Pagamento';
  };

  const transferInterval = () => {
    if (receiver.transfer_settings.transfer_interval === 'Daily')
      return 'Diário';

    if (receiver.transfer_settings.transfer_interval === 'Weekly')
      return 'Semanal';

    return 'Mensal';
  };

  return (
    <Card className="card new-layout mt-4">
      <CardBody className="card-body new-layout px-3 py-3">
        <Row className="w-100 mx-0">
          <Col className="d-flex align-items-start justify-content-start mx-0 font-size-18 font-weight-bold">
            {receiver.description}
            <Badge
              color="success"
              style={{
                width: 'fit-content',
                borderRadius: '9999px',
              }}
              className="font-size-14 py-2 px-3 ml-4"
            >
              Saldo: {formatPrice(receiver.balance_data)}
            </Badge>
          </Col>
        </Row>
        <Row className="w-100 mx-0">
          <Col className="d-flex flex-column align-items-start justify-content-center w-100 mx-0 font-size-14 mt-3">
            <b>Nome</b>
            <p className="text-muted">{receiver.name}</p>
          </Col>
        </Row>
        <Row className="w-100 mx-0">
          <Col className="d-flex flex-column align-items-start justify-content-center w-100 mx-0 font-size-14 mt-1">
            <b>Tipo de Serviço</b>
            <p className="text-muted">{serviceType()}</p>
          </Col>
          <Col className="d-flex flex-column align-items-start justify-content-center w-100 mx-0 font-size-14 mt-1">
            <b>Intervalo de Transferência</b>
            <p className="text-muted">{transferInterval()}</p>
          </Col>
          <Col className="d-flex flex-column align-items-start justify-content-center w-100 mx-0 font-size-14 mt-1">
            <b>Dia da Transferência</b>
            <p className="text-muted">
              {receiver.transfer_settings.transfer_day}
            </p>
          </Col>
          <Col className="d-flex flex-column align-items-start justify-content-center w-100 mx-0 font-size-14 mt-1">
            <b>Receber Pagamentos Automaticamente</b>
            <p className="text-muted">
              {receiver.transfer_settings.transfer_enabled ? 'Sim' : 'Não'}
            </p>
          </Col>
        </Row>
        <Row className="mt-3 w-100 mx-0 d-flex flex-lg-nowrap justify-content-end">
          {/* <Button
            className="new-layout-btn new-layout-btn-transparent main d-flex align-items-center justify-content-center mb-2 mb-lg-0"
            color="primary"
          >
            Lista de Movimentações
          </Button> */}
          <Button
            className="new-layout-btn main d-flex align-items-center justify-content-center ml-lg-3"
            color="primary"
            onClick={() =>
              history.push(`/recebedores-split/${receiver.id}/editar`)
            }
          >
            Editar Recebedor
          </Button>
        </Row>
      </CardBody>
    </Card>
  );
};

const SplitReceiver = () => {
  const { state } = useLocation();

  const roles = useSelector(({ user }) => user.profile.roles);

  const [loading, setLoading] = useState(true);
  const [receivers, setReceivers] = useState([]);
  const [pagination, setPagination] = useState(
    { current_page: state?.page } || null
  );

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const params = useMemo(
    () => ({
      page: pagination?.current_page > 1 ? pagination?.current_page : 1,
      size: 10,
    }),
    [pagination]
  );

  const receiversUrl = useMemo(() => `/${role}/recipients`, [role]);

  const loadReceiverWithdrawal = useCallback(
    async receiver => {
      const { data } = await api.get(
        `/${role}/recipients/${receiver.id}/balance`
      );

      return {
        ...receiver,
        balance_data: data.waiting_funds_amount,
      };
    },
    [role]
  );

  const loadReceivers = useCallback(
    async data => {
      if (!data) return;

      await Promise.all(data.recipients.map(loadReceiverWithdrawal)).then(
        setReceivers
      );
      setPagination(data.meta);
    },
    [loadReceiverWithdrawal]
  );

  const fetchReceivers = useCallback(async () => {
    const { data } = await api.get(receiversUrl);

    await loadReceivers(data);
  }, [receiversUrl, loadReceivers]);

  useEffect(() => {
    setLoading(true);
    fetchReceivers().then(() => setLoading(false));
  }, [fetchReceivers]);

  return (
    <div className="page-content">
      <Container className="container new-layout wide">
        <Row className="w-100 mx-0">
          <Col xs={12} lg={6} className="px-0">
            <PageHeader
              pageTitle="RECEBEDORES"
              responsivePosition="start"
              desktopPosition="start"
            />
          </Col>
          <Col
            xs={12}
            lg={6}
            className="px-0 d-flex align-items-center justify-content-end"
          >
            <Button
              className="new-layout-btn main d-flex align-items-center justify-content-center"
              color="primary"
              size="lg"
              onClick={() => history.push('/recebedores-split/registrar')}
            >
              Adicionar Recebedor
            </Button>
          </Col>
        </Row>

        {loading ? (
          <Loading />
        ) : (
          <>
            {receivers.map(receiver => (
              <SplitReceiverCard receiver={receiver} />
            ))}

            {receivers?.length ? (
              <Row className="justify-content-end mb-4 mr-0">
                <Pagination
                  paginationMeta={pagination}
                  url={receiversUrl}
                  setData={loadReceivers}
                  setLoading={setLoading}
                  params={params}
                />
              </Row>
            ) : null}

            {!receivers?.length ? (
              <h3 className="mt-3">Nenhum recebedor encontrado.</h3>
            ) : null}
          </>
        )}
      </Container>
    </div>
  );
};

export default SplitReceiver;
