/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Label,
  UncontrolledTooltip,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { toast } from 'react-toastify';
import Loading from '~/components/Loading';
import { saveUserData } from '~/store/modules/user/actions';

import Input from '~/components/Inputs/Input';
import InputSelectAsync from '~/components/Inputs/InputSelectAsync';
import api from '~/services/api';
import history from '~/services/history';

import { logoutUser } from '~/store/modules/auth/login/actions';
import { defineAlignment } from '~/util/newLayoutHelpers';
import { LG } from '~/util/breakpointWidths';
import PageHeader from '~/components/Common/PageHeader';
import UserHeaderInfo from '~/components/Common/UserHeaderInfo';
import { copyStringToClipboard } from '~/util/copyToClipboard';
import IconButton from '~/components/IconButton';
import InputMask from '~/components/Inputs/InputMask';
import { maskBuilder } from '~/util/maskTelephone';
import InputSelect from '~/components/Inputs/InputSelect';
import useRoles from '~/hooks/useRoles';
import { getRole } from '~/util/getRole';

const EditUserProfile = () => {
  const roles = useSelector(({ user }) => user.profile.roles);
  const dispatch = useDispatch();

  const [telephoneV, setTelephone] = useState('');

  const roleUser = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const formRef = useRef(null);

  const { user_id } = useParams();

  const [user, setUser] = useState();

  const [storesSearched, setStoresSearched] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [selectedStores, setSelectedStores] = useState([]);

  const [showStorePicker, setShowStorePicker] = useState(false);

  const [allStores, setAllStores] = useState();

  const loggedUser = useSelector(({ user: User }) => User.profile);
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [loading, setLoading] = useState(true);

  const [userRoles, setUserRoles] = useState([]);

  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [generatingNewPassword, setGeneratingNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const [passwordError, setPasswordError] = useState(false);

  const { isStoreManager, isAdmin, isSupport } = useRoles();

  // const amIManager = useMemo(
  //   () => loggedUser?.roles?.find(role => role.slug === 'store-manager'),
  //   [loggedUser]
  // );

  const userIsSalesman = useMemo(
    () => userRoles?.some(role => role?.slug === 'salesman'),
    [userRoles]
  );

  const userIsStockist = useMemo(
    () => userRoles?.some(role => role?.slug === 'stockist'),
    [userRoles]
  );

  const userIsAdmin = useMemo(
    () => userRoles?.some(role => role?.slug === 'administrator'),
    [userRoles]
  );

  const userIsStoreManager = useMemo(
    () => userRoles?.some(role => role?.slug === 'store-manager'),
    [userRoles]
  );

  const userIsConsultant = useMemo(
    () => userRoles?.some(role => role?.slug === 'digital-consultant'),
    [userRoles]
  );

  const userIsFinancier = useMemo(
    () => userRoles?.some(role => role?.slug === 'financier'),
    [userRoles]
  );

  const userIsOnlyAdmin = useMemo(() => {
    if (userIsAdmin && !(userIsSalesman || userIsStockist)) {
      return true;
    }

    return false;
  }, [userIsAdmin, userIsSalesman, userIsStockist]);

  const ROLES = useMemo(() => {
    return [
      {
        id: 1,
        name: 'Vendedor',
        slug: 'salesman',
        checked: userIsSalesman,
        show: userRoles?.some(role => role?.slug === 'salesman'),
        available: true,
      },
      {
        id: 2,
        name: 'Estoquista',
        slug: 'stockist',
        checked: userRoles?.some(role => role?.slug === 'stockist'),
        show: userRoles?.some(role => role?.slug === 'stockist'),
        available: true,
      },
      {
        id: 3,
        name: 'Administrador',
        slug: 'administrator',
        checked: userRoles?.some(role => role?.slug === 'administrator'),
        show: true,
        available:
          (isAdmin || isSupport) &&
          !(
            userIsConsultant ||
            userIsFinancier ||
            userIsStoreManager ||
            userIsSalesman
          ),
      },
      {
        id: 4,
        name: 'Gerente',
        slug: 'store-manager',
        checked: userRoles?.some(role => role?.slug === 'store-manager'),
        show: true,
        available:
          !isStoreManager &&
          !(
            userIsConsultant ||
            userIsFinancier ||
            userIsSalesman ||
            userIsAdmin
          ),
      },
      {
        id: 5,
        name: 'Vendedor Digital',
        slug: 'digital-consultant',
        checked: userRoles?.some(role => role?.slug === 'digital-consultant'),
        show: true,
        available: !userRoles?.some(
          role => role?.slug !== 'digital-consultant'
        ),
      },
    ];
  }, [
    userRoles,
    isAdmin,
    isSupport,
    isStoreManager,
    userIsAdmin,
    userIsStoreManager,
    userIsFinancier,
    userIsConsultant,
    userIsSalesman,
  ]);

  const [listOfRoles, setListOfRoles] = useState(ROLES);

  const cannotSaveData = useMemo(() => {
    if (!selectedStores.length) {
      if (
        !allStores &&
        isAdmin &&
        (userIsStoreManager ||
          userIsStockist ||
          userIsSalesman ||
          userIsFinancier ||
          userIsConsultant)
      ) {
        return true;
      }
    }
    return false;
  }, [
    allStores,
    isAdmin,
    userIsSalesman,
    userIsStockist,
    userIsStoreManager,
    userIsConsultant,
    selectedStores,
    userIsFinancier,
  ]);

  useEffect(() => {
    document.title = 'Editar Usuário | Viddy';
    const loadUser = async () => {
      try {
        const { data } = await api.get(`/${roleUser}/users/${user_id}`);
        setUser(data.user);
        setUserRoles(data.user.roles);
        setTelephone(data.user.telephone);
        setAllStores(data.user.manages_all_stores);
        const AlreadyHave = data.user.roles
          ? listOfRoles.filter(
              role =>
                !data.user.roles.find(item => item.slug.includes(role.slug))
            )
          : listOfRoles;
        setListOfRoles(AlreadyHave);
        setLoading(false);
      } catch (err) {
        history.goBack();
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Usuário não encontrado.');
        }
      }
    };

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  useEffect(() => {
    if (user?.managed_stores) {
      const stores = user.managed_stores.map(s => ({
        value: s.id,
        label: s.fantasy_name,
      }));

      if (user?.stock) {
        const store = { value: user.stock.id, label: user.stock.fantasy_name };
        setSelectedStore(store);
      }

      setSelectedStores(stores);
    }
  }, [user]);

  useEffect(() => {
    if (allStores) {
      const loadStores = async () => {
        try {
          const { data } = await api.get(
            `/${
              isAdmin || isSupport ? 'administrator' : 'store-manager'
            }/stores/all`
          );

          const stores = data.map(s => ({
            value: s.id,
            label: s.fantasy_name,
          }));

          setSelectedStores(stores);
        } catch (err) {
          console.log(err);
        }
      };
      loadStores();
    }
  }, [allStores, isAdmin, isSupport]);

  const onSubmitHandler = async data => {
    try {
      const noStartingWhitespace = value => {
        if (value && value.startsWith(' ')) {
          return false;
        }
        return true;
      };

      const onlyLowerCase = value => {
        if (value && value !== value.toLowerCase()) {
          return false;
        }
        return true;
      };

      formRef.current.setErrors({});
      let schema = Yup.object().shape({
        name: Yup.string()
          .required('Digite o nome do usuário')
          .test(
            'noStartingWhitespace',
            'O nome não pode começar com um espaço!',
            noStartingWhitespace
          ),
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('Digite o e-mail do usuário')
          .test(
            'onlyLowerCase',
            'O e-mail deve conter apenas letras minúsculas!',
            onlyLowerCase
          ),
        telehpone: Yup.string().notRequired(),
        password: data.password
          ? Yup.string().min(6, 'Digite no mínimo 6 caracteres para senha')
          : undefined,
      });

      // if (isAdmin) {
      //   if (!allStores) {
      //     if (!data.ids) {
      //       data = {
      //         ...data,
      //         ids: selectedStores.map(store => store.value),
      //       };
      //     }
      //     schema = Yup.object().shape({
      //       name: Yup.string().required('Digite o nome do usuário'),
      //       email: Yup.string()
      //         .email('Digite um e-mail válido')
      //         .required('Digite o e-mail do usuário'),
      //       ids: Yup.string().required('Selecione uma loja'),
      //     });
      //   } else {
      //     schema = Yup.object().shape({
      //       name: Yup.string().required('Digite o nome do usuário'),
      //       email: Yup.string()
      //         .email('Digite um e-mail válido')
      //         .required('Digite o e-mail do usuário'),
      //       ids: Yup.string(),
      //     });
      //   }
      // } else {
      if (isAdmin || isSupport) {
        schema = Yup.object().shape({
          name: Yup.string()
            .required('Digite o nome do usuário')
            .test(
              'noStartingWhitespace',
              'O nome não pode começar com um espaço!',
              noStartingWhitespace
            ),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('Digite o e-mail do usuário')
            .test(
              'onlyLowerCase',
              'O e-mail deve conter apenas letras minúsculas!',
              onlyLowerCase
            ),
          ids: Yup.string(),
          password: data.password
            ? Yup.string().min(6, 'Digite no mínimo 6 caracteres para senha')
            : undefined,
        });
      } else {
        schema = Yup.object().shape({
          name: Yup.string()
            .required('Digite o nome do usuário')
            .test(
              'noStartingWhitespace',
              'O nome não pode começar com um espaço!',
              noStartingWhitespace
            ),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('Digite o e-mail do usuário')
            .test(
              'onlyLowerCase',
              'O e-mail deve conter apenas letras minúsculas!',
              onlyLowerCase
            ),
          ids: Yup.string(),
          password: data.password
            ? Yup.string().min(6, 'Digite no mínimo 6 caracteres para senha')
            : undefined,
        });
      }

      const indexRoles = userRoles.length ? userRoles.map(role => role.id) : [];

      const indexStores = selectedStores.map(({ value }) => value);

      data = {
        ...data,
        roles: indexRoles,
        managed_stores: indexStores,
        manages_all_stores: Boolean(userIsAdmin || allStores),
      };

      if (userRoles.length > 0) {
        await schema.validate(data, {
          abortEarly: false,
        });

        const ids = selectedStores.map(currentStore => currentStore.value);

        if (userIsAdmin || allStores) {
          await api.patch(
            `/${roleUser}/users/${user_id}/managed_stores/set-all`
          );
        } else {
          await api.patch(`/${roleUser}/users/${user_id}/managed_stores/set`, {
            // ids: data.ids,
            ids,
          });
        }

        // if (allStores) {
        //   await api.patch(
        //     `/${roleUser}/users/${user_id}/managed_stores/set-all`
        //   );
        // } else {
        //   await api.patch(
        //     `/${roleUser}/users/${user_id}/managed_stores/set`,
        //     {
        //       ids: data.ids,
        //     }
        //   );
        // }
      }

      delete data.ids;

      if (!data.password) delete data.password;

      await api.put(`/${roleUser}/users/${user_id}`, data);

      toast.success('Usuário editado com sucesso!');

      if (loggedUser.id === user_id) {
        const {
          data: { user: userData },
        } = await api.get('auth/me');

        dispatch(saveUserData(userData));
      }

      history.push('/usuarios');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          console.log(e.path);
          if (e.path === 'password') setPasswordError(true);
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
      }

      if (err && err.response?.status === 403) {
        toast.success('Dados alterados com sucesso. Faça o login novamente!');

        dispatch(logoutUser());
      }
    }
  };

  const addRole = id => {
    const newRole = listOfRoles.find(role => role.id === id);
    const removePrev = listOfRoles.filter(role => role.id !== id);
    setListOfRoles(removePrev);
    setUserRoles([...userRoles, newRole]);
  };

  const removeRole = id => {
    const remove = userRoles.filter(role => role.id !== id);
    const addPrev = ROLES.find(role => role.id === id);

    setUserRoles(remove);
    setListOfRoles([...listOfRoles, addPrev]);
  };

  const handleRole = id => {
    if (userRoles.some(role => role.id === id)) {
      removeRole(id);
    } else {
      if (id === 5) {
        // if is consultant (bug fix)
        if (selectedStores.length) {
          if (!selectedStore) {
            setSelectedStore(selectedStores[0]);
          }

          setSelectedStores([selectedStores[0]]);
        }
      }
      addRole(id);
    }
  };

  const loadOptions = async (inputValue, cb) => {
    if (inputValue.length < 2) return;
    const { data } = await api.get('/stores', {
      params: { query: inputValue },
    });

    if (data.stores.length === 0) {
      return;
    }

    const filteredStores = data.stores.filter(s => {
      const isSelected = selectedStores.some(store => store.value === s.id);
      return isSelected === false;
    });

    const storesSearch = filteredStores.map(store => ({
      value: store.id,
      label: store.fantasy_name,
    }));

    setStoresSearched(prev => [...prev, ...data.stores]);

    cb(storesSearch);
  };

  // const setStoreSelectable = option => {
  //   const store = storesSearched.find(p => p.id === option.value);

  //   if (!store) return;

  //   const selectStore = {
  //     value: store.id,
  //     label: store.fantasy_name,
  //   };
  //   setSelectedStore(selectStore);

  //   formRef.current.setFieldValue('ids', selectStore.value);
  // };

  const setStoreSelectable = async option => {
    if (!option) return;

    const store = selectedStores.find(p => p.value === option.value);

    if (!store) return;

    const selectStore = {
      value: store.value,
      label: store.label,
    };

    setSelectedStore(selectStore);
  };

  const addToSelectedStores = option => {
    if (option) {
      const store = storesSearched.find(p => p.id === option.value);

      if (!store) return;

      const selectStore = {
        value: store.id,
        label: store.fantasy_name,
      };

      setSelectedStores([...selectedStores, selectStore]);
      setShowStorePicker(false);

      // formRef.current.setFieldValue('ids', selectStore.value);
    }
  };

  const removeFromSelectedStores = option => {
    const selectedStoresDraft = selectedStores.filter(s => {
      return s.value !== option.value;
    });

    setSelectedStores(selectedStoresDraft);
  };

  useEffect(() => {
    const getNewPassword = async () => {
      try {
        const {
          data: { password },
        } = await api.patch(
          `${isAdmin || isSupport ? 'administrator' : 'store-manager'}/users/${
            user.id
          }/password/new`
        );
        if (password) {
          setNewPassword(password);
          setGeneratingNewPassword(false);
          toast.success('Nova senha gerada com sucesso');
        }
      } catch (err) {
        setShowNewPasswordModal(false);
        setGeneratingNewPassword(false);
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            'Ocorreu um erro ao tentar gerar uma nova senha. Tente mais tarde ou contate um administrador!'
          );
        }
      }
    };
    if (showNewPasswordModal && generatingNewPassword) {
      getNewPassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNewPasswordModal, generatingNewPassword]);

  // useEffect(() => {
  //   if (selectedStores.length && userIsOnlyAdmin) {
  //     setSelectedStores([]);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userIsOnlyAdmin]);

  useEffect(() => {
    if (!userRoles.length && allStores) {
      setAllStores(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles]);

  return (
    <div className="page-content">
      <Container className="container new-layout">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              pageTitle="EDITAR USUÁRIO"
              backTo="/usuarios"
              responsivePosition="start"
              desktopPosition="center"
            />
            <Form
              ref={formRef}
              onSubmit={onSubmitHandler}
              initialData={{
                name: user?.name,
                email: user?.email,
                identification_code: user?.identification_code,
              }}
            >
              <Card className="card new-layout">
                <CardBody className="card-body new-layout">
                  <UserHeaderInfo user={user} />
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 px-lg-4">
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup>
                            <Label>Nome</Label>
                            <Input
                              name="name"
                              type="text"
                              placeholder="Nome do usuário"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup>
                            <Label>E-mail</Label>
                            <Input
                              name="email"
                              type="text"
                              placeholder="E-mail"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup>
                            <Label>Telefone</Label>
                            <InputMask
                              mask={maskBuilder(telephoneV)}
                              maskChar={null}
                              name="telephone"
                              value={telephoneV}
                              placeholder="Telefone do usuário"
                              type="text"
                              onChange={e => {
                                if (
                                  e.target.value.length < 15 ||
                                  e.target.value[5] === '9'
                                ) {
                                  setTelephone(e.target.value);
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup className="mb-0">
                            <Label>Senha</Label>
                            <Input
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                              onFocus={() => {
                                formRef.current.setFieldError('password', '');
                                setPasswordError(false);
                              }}
                              placeholder="Senha do usuário"
                            />
                            <div
                              style={{
                                position: 'relative',
                                left: 'calc(100% - 35px)',
                                bottom: passwordError ? '50px' : '32.5px',
                              }}
                            >
                              <IconButton
                                icon={`las ${
                                  showPassword ? 'la-eye' : 'la-eye-slash'
                                }`}
                                color={showPassword ? '' : 'secondary'}
                                tip={
                                  showPassword
                                    ? 'Esconder senha'
                                    : 'Mostrar senha'
                                }
                                forceTipe
                                click={() => setShowPassword(!showPassword)}
                                fontSize={25}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3} className="my-2 my-lg-0 px-0  px-lg-4">
                      <Row className="w-100 mx-0" style={{ height: '28px' }}>
                        <Col xs={6} className="px-0">
                          <Label>Loja(s)</Label>
                        </Col>
                        <Col xs={6} align="end" className="px-0">
                          <i
                            className={`las la-${
                              showStorePicker ? 'times' : 'plus'
                            } text-muted font-size-18 outline-none ${
                              allStores || userIsOnlyAdmin || !userRoles.length
                                ? 'd-none'
                                : ''
                            }`}
                            role="button"
                            tabIndex={0}
                            aria-label="add-store"
                            onClick={() => {
                              setShowStorePicker(!showStorePicker);
                            }}
                          />
                        </Col>
                      </Row>
                      {(isAdmin || isStoreManager || isSupport) &&
                        (userIsStockist ||
                          userIsStoreManager ||
                          userIsSalesman ||
                          userIsFinancier ||
                          userIsConsultant) && (
                          <Row
                            style={{
                              height: '46px',
                            }}
                            className="w-100 mx-0 d-flex flex-row align-items-center"
                          >
                            <Col className="px-0">
                              <FormGroup>
                                {!showStorePicker ? (
                                  <CustomInput
                                    type="checkbox"
                                    id="setAllStores"
                                    name="setAllStores"
                                    label="Todas as Lojas"
                                    checked={allStores}
                                    defaultValue={allStores}
                                    className="text-primary mt-3 mb-4"
                                    onChange={() => setAllStores(!allStores)}
                                  />
                                ) : (
                                  <InputSelectAsync
                                    loadOptions={loadOptions}
                                    name="ids"
                                    onChange={addToSelectedStores}
                                    placeholder="Nome da loja"
                                    type="text"
                                    isClearable
                                    loadingMessage={() => 'Buscando lojas...'}
                                    onBlur={() => {}}
                                  />
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      {/* {isAdmin && userIsConsultant && (
                        <Row className="w-100 mx-0">
                          <Col className="px-0">
                            <FormGroup>
                              {selectedStores.length === 1 ? (
                                <Input
                                  name="ids"
                                  type="text"
                                  placeholder="Nome da loja"
                                  classRest="input-disabled"
                                  disabled
                                />
                              ) : (
                                <InputSelectAsync
                                  loadOptions={loadOptions}
                                  name="ids"
                                  onChange={addToSelectedStores}
                                  placeholder="Nome da loja"
                                  type="text"
                                  loadingMessage={() => 'Buscando lojas...'}
                                  onBlur={() => {}}
                                />
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      )} */}
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          {!allStores && !userIsAdmin ? (
                            <>
                              {selectedStores.length > 0 ? (
                                <>
                                  {userRoles.length > 0 && (
                                    <PerfectScrollbar className="new-layout-managed-stores-scrollbar edit">
                                      {selectedStores.map(store => (
                                        <div
                                          key={store.value}
                                          className="store-ticket my-2"
                                        >
                                          <Row className="w-100">
                                            <Col
                                              xs={11}
                                              className="text-truncate pr-0"
                                              id={`storeTooltip-${store.value}`}
                                            >
                                              {store.label}
                                            </Col>
                                            <UncontrolledTooltip
                                              placement="top"
                                              target={`storeTooltip-${store.value}`}
                                            >
                                              {store.label}
                                            </UncontrolledTooltip>
                                            <Col
                                              xs={1}
                                              className="px-0"
                                              align="end"
                                            >
                                              <i
                                                className="las la-times text-muted font-size-16 outline-none"
                                                role="button"
                                                tabIndex={0}
                                                aria-label="remove-store"
                                                onClick={() => {
                                                  removeFromSelectedStores(
                                                    store
                                                  );
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                    </PerfectScrollbar>
                                  )}
                                </>
                              ) : (
                                <>
                                  {cannotSaveData && (
                                    <Alert color="danger">
                                      Selecione pelo menos uma loja para
                                      prosseguir!
                                    </Alert>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {!userIsAdmin && (
                                <Alert color="info">
                                  Todas as lojas estão selecionadas.
                                </Alert>
                              )}
                            </>
                          )}
                          {!userRoles.length && (
                            <Alert color="info">
                              Enquanto nenhum cargo for atribuído ao usuário,
                              não se faz necessário que se atribua uma loja ao
                              mesmo!
                            </Alert>
                          )}
                          {userIsOnlyAdmin && (
                            <Alert color="info">
                              Enquanto apenas o cargo de administrador for
                              atribuído ao usuário, não se faz necessário que se
                              atribua uma loja ao mesmo.
                            </Alert>
                          )}
                        </Col>
                      </Row>
                      <Row
                        className="w-100 mx-0"
                        style={{ paddingTop: '15px' }}
                      >
                        <Col className="px-0">
                          <Label>Loja Matriz</Label>
                          <InputSelect
                            options={selectedStores}
                            name="stock_id"
                            onChange={setStoreSelectable}
                            isSearchable
                            menuShouldBlockScroll
                            placeholder="Selecione a loja"
                            type="text"
                            value={selectedStore}
                            loadingMessage={() => 'Buscando lojas...'}
                            onBlur={() => {}}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3} className="my-2 my-lg-0 px-0 px-lg-4">
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <Label>Cargo(s)</Label>
                          <i
                            id="rolesTooltip"
                            className="las la-info-circle font-size-18 ml-1"
                          />
                        </Col>
                      </Row>
                      <UncontrolledTooltip
                        placement="top"
                        target="rolesTooltip"
                      >
                        Um administrador não pode ser um vendedor. Um gerente
                        não pode ser administrador e/ou vendedor e vice-versa.
                        Um vendedor não pode ter nenhum outro cargo.
                      </UncontrolledTooltip>
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          {ROLES.map(role => (
                            <CustomInput
                              type="checkbox"
                              id={`${role.slug}-checkbox`}
                              name={role.slug}
                              label={role.name}
                              checked={role.checked}
                              className={`text-primary mb-2 ${
                                !role.available
                                  ? 'text-decoration-line-through'
                                  : ''
                              } ${!role.show && 'd-none'}`}
                              disabled={!role.available}
                              onChange={() => {
                                handleRole(role.id);
                              }}
                            />
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col
                      className="px-0 px-lg-4"
                      align={defineAlignment(windowSize, LG, 'center', 'end')}
                    >
                      <Button
                        color="primary"
                        className="new-layout-btn generate-new-password-btn"
                        onClick={() => {
                          setGeneratingNewPassword(true);
                          setShowNewPasswordModal(true);
                        }}
                      >
                        Nova senha temporária
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row className="w-100 mx-0 my-4 ">
                <Col
                  align={defineAlignment(windowSize, LG, 'center', 'end')}
                  className="px-0"
                >
                  <Button
                    color="primary"
                    className="new-layout-btn main px-4 my-4"
                    disabled={cannotSaveData}
                    size="lg"
                    type="submit"
                  >
                    Salvar Alterações
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Container>
      <Modal isOpen={showNewPasswordModal}>
        <ModalHeader>
          {generatingNewPassword ? 'Aguarde...' : 'Nova senha gerada'}
        </ModalHeader>
        <ModalBody>
          {generatingNewPassword ? (
            <Loading message="Aguarde enquanto uma nova senha é gerada!" />
          ) : (
            <>
              <span>{`Esta é a nova senha de ${user?.name}, você já pode copiá-la!`}</span>
              <Row className="align-items-center justify-content-center">
                <Col xs={10}>
                  <input
                    type="text"
                    // name="new_password"
                    id="new-password"
                    className="my-4 w-100 form-control disabled"
                    readOnly
                    value={newPassword}
                  />
                </Col>
                <Col className="px-0" xs={2}>
                  <IconButton
                    id="copy-new-password"
                    icon="las la-copy"
                    containerClass="ml-2"
                    fontSize={32}
                    color="info"
                    click={() => {
                      copyStringToClipboard(newPassword, 'Senha copiada!');
                    }}
                    tip="Copiar"
                  />
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className={` new-layout-btn main float-right ${
              generatingNewPassword && 'd-none'
            }`}
            color="primary"
            onClick={() => {
              setShowNewPasswordModal(false);
            }}
          >
            Pronto
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditUserProfile;
