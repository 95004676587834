import { Container, Badge, Row, Col, Button } from 'reactstrap';
import history from '~/services/history';
import SalesList from './SalesList';
import CustomersWallet from '../CustomersWallet';

const routeToComponent = route => {
  const routes = {
    '/': 1,
    '/carteira-de-clientes': 2,
  };

  if (routes[route]) return routes[route];

  return 1;
};

const SalesIndex = () => {
  const activeTab = routeToComponent(window.location.pathname);

  return (
    <>
      <div className="page-content">
        <Container className="container new-layout wide">
          <Row className="mx-0 mb-3 d-none d-lg-flex">
            <Col xs={12} className="px-0">
              <Badge
                style={{
                  fontSize: '1rem',
                  color: activeTab === 1 ? '#2544B1' : '#56575B',
                  backgroundColor: '#CDD8FF00',
                  padding: '0.7rem',
                  cursor: 'pointer',
                  borderRadius: '0',
                  borderBottom: activeTab === 1 ? '2px solid #2544B1' : '',
                }}
                className="mr-1"
                onClick={() => history.push('/')}
              >
                Vendas
              </Badge>
              <Badge
                style={{
                  fontSize: '1rem',
                  color: activeTab === 2 ? '#2544B1' : '#56575B',
                  backgroundColor: '#CDD8FF00',
                  padding: '0.7rem',
                  cursor: 'pointer',
                  borderRadius: '0',
                  borderBottom: activeTab === 2 ? '2px solid #2544B1' : '',
                }}
                onClick={() => history.push('/carteira-de-clientes')}
              >
                Clientes
              </Badge>
            </Col>
          </Row>

          {activeTab === 1 && (
            <>
              <SalesList />
            </>
          )}
          {activeTab === 2 && (
            <>
              <CustomersWallet />
            </>
          )}
        </Container>
      </div>
      <div
        className="w-100 mobile-to-register-sale-btn d-lg-none d-flex align-items-center"
        style={{
          position: 'absolute !important',
          bottom: '0',
          left: '0',
          right: '0',
          height: '3.5rem',
          maxWidth: 'none',
          backgroundColor: 'white',
          boxShadow: '1px 0px 25px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Badge
          className="btn btn-default w-100"
          style={{
            fontSize: '1rem',
            color: activeTab === 1 ? '#2544B1' : '#56575B',
            backgroundColor: '#CDD8FF00',
            padding: '0.7rem',
            cursor: 'pointer',
          }}
          onClick={() => history.push('/')}
        >
          Vendas
        </Badge>
        <Button
          className="d-flex justify-items-center align-items-center"
          color="primary"
          style={{
            borderRadius: '9999px',
            height: '2.5rem',
            width: '2.5rem',
            paddingLeft: '0.4rem',
            margin: 'auto',
          }}
          onClick={() => history.push('/registrar')}
        >
          <i className="las la-plus font-size-26" />
        </Button>
        <Badge
          className="btn btn-default w-100"
          style={{
            fontSize: '1rem',
            color: activeTab === 2 ? '#2544B1' : '#56575B',
            backgroundColor: '#CDD8FF00',
            padding: '0.7rem',
            cursor: 'pointer',
          }}
          onClick={() => history.push('/carteira-de-clientes')}
        >
          Clientes
        </Badge>
      </div>
    </>
  );
};

export default SalesIndex;
