/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Row,
  Card,
  Col,
  FormGroup,
  Button,
  Label,
  CardBody,
  // CustomInput,
} from 'reactstrap';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import api from '~/services/api';

import {
  createClient,
  saveClient,
  saveInformationsAndUser,
} from '~/store/modules/cart/actions';

import Input from '~/components/Inputs/Input';
import InputMask from '~/components/Inputs/InputMask';
import { maskBuilder } from '~/util/maskTelephone';
import { capitalize, normalizedText } from '~/util/format';
import { getRole } from '~/util/getRole';
import TargetUserModal from '../TargetUserAlert';

const useQuery = () => new URLSearchParams(useLocation().search);

const ClientForm = ({ setTargetUserId }) => {
  const formRef = useRef(null);

  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const Cart = useSelector(({ cart }) => cart.cart);

  const Client = useSelector(({ cart }) => cart.client);

  const [telephoneV, setTelephone] = useState(Client?.telephone || '');

  const [handleDisabled, setHandleDisabled] = useState(false);
  const [searchedTelephone, setSearchedTelephone] = useState('');
  const [userSearchLoading, setUserSearchLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSellerSuggestions, setShowSellerSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [sellersSuggestions, setSellersSuggestions] = useState([]);
  const [fullName, setFullName] = useState(Client?.name);
  const [sellerName, setSellerName] = useState('');
  const [sellerId, setSellerId] = useState(null);

  const [showTargetUserAlert, setShowTargetUserAlert] = useState(false);

  const dispatch = useDispatch();

  const formatNumber = tel => {
    const phone = tel.replace(/\D/g, '');
    const ddd = phone.slice(0, 2);
    let first;
    let last;
    if (phone[2] === '9' && phone.length === 11) {
      first = phone.slice(2, 7);
      last = phone.slice(7, 11);
    } else {
      first = phone.slice(2, 6);
      last = phone.slice(6, 10);
    }
    return `(${ddd}) ${first}-${last}`;
  };

  const handleValidSubmit = async data => {
    setHandleDisabled(true);
    try {
      formRef.current.setErrors({});

      if (data.telephone.length === 15 && data.telephone[5] !== '9')
        data.telephone = formatNumber(data.telephone);

      const invalidTelephones = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
      ].map(num => num.repeat(8));

      data.name = capitalize(data.name);

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome é obrigatório'),
        telephone: Yup.string()
          .required('O whatsapp é obrigatório')
          .min(14, 'O whatsapp é obrigatório')
          .test({
            name: 'Telefones inválidos',
            exclusive: false,
            params: { invalidTelephones },
            message: 'Telefone inválido',
            test: value =>
              !invalidTelephones.some(tel =>
                value.replaceAll(/\D/g, '').endsWith(tel)
              ),
          }),
        email: Yup.string()
          .email('Email inválido')
          .oneOf(
            [normalizedText(data.email)],
            'E-mail no formato inválido, somente letras, números e pontos são permitidos'
          ),
        cpf: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { order_number } = Cart?.order_number || data;

      setTargetUserId(sellerId);
      if (!Client) {
        dispatch(
          createClient({
            data,
            formRef,
            order_number,
            page_state: {
              setHandleDisabled,
            },
          })
        );
      } else {
        dispatch(
          saveInformationsAndUser({
            formRef,
            order_number,
            data,
            page_state: {
              setHandleDisabled,
            },
          })
        );
      }
    } catch (err) {
      console.error(err);
      const validationErrors = {};

      if (err instanceof ValidationError) {
        err.inner.forEach((e, index) => {
          validationErrors[e.path] = e.message;
          if (index === 0) {
            const input = document.getElementById(e.path);
            if (input) {
              setTimeout(() => {
                input.scrollIntoView(false);
              }, 200);
            }
          }
        });
        formRef.current.setErrors(validationErrors);
      }
      if (err?.response) {
        toast.error(err?.response?.data?.message);
      }
      setHandleDisabled(false);
    }
  };

  const searchByTelephone = async value => {
    if (!value || searchedTelephone === value) return;

    setSearchedTelephone(value);
    setUserSearchLoading(true);

    try {
      const {
        data: { client },
      } = await api.get(`clients/find?telephone=${value}`);

      const { name, cpf, email } = client;

      dispatch(saveClient(client));
      const data = formRef.current.getData();

      formRef.current.setData({
        ...data,
        name,
        cpf: cpf || '',
        email,
      });
      setFullName(name);
      setUserSearchLoading(false);
    } catch (err) {
      setUserSearchLoading(false);
      // dispatch(saveClient(null));
    }
  };

  // const searchByCPF = async value => {
  //   if (searchedCPF === value) return;
  //   setSearchedCPF(value);
  //   try {
  //     const {
  //       data: { client },
  //     } = await api.get(`clients/find?cpf=${value}`);

  //     const { name, cpf, telephone, email } = client;

  //     dispatch(saveClient(client));
  //     const data = formRef.current.getData();

  //     formRef.current.setData({
  //       ...data,
  //       name,
  //       cpf: cpf || '',
  //       telephone: telephone || '',
  //       email,
  //     });

  //     if (telephone !== null) {
  //       setTelephone(telephone);
  //     }
  //   } catch (err) {
  //     // toast.error('Nenhum cliente encontrado!');
  //     if (!Client) {
  //       dispatch(saveClient(null));
  //     }
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const clientId = useQuery().get('clientId');

  useEffect(() => {
    if (!clientId) return;

    const loadClientById = async () => {
      const {
        data: { client },
      } = await api.get(`/clients/${clientId}`);

      dispatch(saveClient(client));

      setFullName(client.name);
      setTelephone(client.telephone);

      formRef.current?.setData({
        cpf: client.cpf || '',
        email: client.email || '',
      });
    };

    loadClientById();
  }, [clientId, dispatch]);

  useEffect(() => {
    const loadSuggestions =
      fullName?.length >= 2
        ? async () => {
            const { data } = await api.get(`/clients/?query=${fullName}`);

            setSuggestions(data.clients);
          }
        : () => {
            setSuggestions([]);
          };

    const debounce = setTimeout(loadSuggestions, 500);

    return () => clearInterval(debounce);
  }, [fullName]);

  useEffect(() => {
    if (role !== 'store-manager') return () => {};

    const loadSuggestions = async () => {
      const { data } = await api.get(
        `store-manager/users/?query=${sellerName}`
      );

      setSellersSuggestions(data.users);
    };

    const debounce = setTimeout(loadSuggestions, 500);

    return () => clearInterval(debounce);
  }, [sellerName, role]);

  return (
    <>
      <Form
        id="client-form"
        onSubmit={handleValidSubmit}
        ref={formRef}
        initialData={{
          ...Client,
        }}
        noValidate
      >
        {/* <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-4">
            Loja
          </Row>
          <Row className="w-100 mx-0 mb-2">
            <Col lg={6} className="px-0 pr-lg-2">
              <Label>Nome da Loja</Label>
              <FormGroup>
                <InputSelect
                  options={userStores}
                  name="store_id"
                  onChange={setStoreSelectable}
                  isSearchable
                  placeholder="Nome da loja"
                  type="text"
                  value={selectedStore}
                  loadingMessage={() => 'Buscando lojas...'}
                  styles={storeSelectorStyles}
                />
              </FormGroup>
            </Col>
            <Col lg={6} className="px-0 px-lg-5">
              <Label>Quem finaliza o pedido?</Label>
              <Row className="w-100 mx-0 py-2">
                <Col lg={6} className="px-0 pr-lg-2">
                  <CustomInput
                    type="radio"
                    id="cart_is_seller"
                    name="cart_is_seller"
                    label="Vendedor"
                    className="text-primary z-index-0 mb-2 mb-lg-0"
                    checked={finishPerson === 'Seller'}
                    onChange={() => {
                      setFinishPerson('Seller');
                    }}
                  />
                </Col>
                <Col lg={6} className="px-0 pl-lg-2">
                  <CustomInput
                    type="radio"
                    id="is_shareable"
                    name="is_shareable"
                    label="Cliente"
                    className="text-primary z-index-0 mb-2 mb-lg-0"
                    checked={finishPerson === 'Client'}
                    onChange={() => {
                      setFinishPerson('Client');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card> */}

        {role === 'store-manager' && (
          <Card className="card new-layout">
            <CardBody className="card-body new-layout">
              <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-4 align-items-center">
                Responsável pela venda
                <i
                  onClick={() => setShowTargetUserAlert(true)}
                  className="las la-info-circle font-size-20 text-info ml-2 cursor-pointer"
                />
              </Row>
              <Row className="w-100 mx-0">
                <Col lg={6} className="px-0 pr-lg-2">
                  <Label>Nome Do Vendedor</Label>
                  <FormGroup className="relative w-100">
                    <Input
                      name="target_user_id"
                      placeholder="Nome do Vendedor"
                      type="text"
                      value={sellerName}
                      onChange={e => setSellerName(e.target.value)}
                      onFocus={() => setShowSellerSuggestions(true)}
                      onBlur={() =>
                        setTimeout(() => setShowSellerSuggestions(false), 220)
                      }
                      role="presentation"
                      autocomplete="do-not-autofill"
                    />
                    {showSellerSuggestions && sellersSuggestions.length > 0 && (
                      <div
                        className="form-control py-0 h-auto"
                        style={{ position: 'absolute', zIndex: '999999' }}
                      >
                        {sellersSuggestions.map(suggestion => (
                          <button
                            key={suggestion.telephone}
                            type="button"
                            className="my-0 py-2 d-block w-100 text-left border-0 bg-white"
                            onClick={() => {
                              setSellersSuggestions([]);
                              setSellerName(suggestion.name);
                              setSellerId(suggestion.id);
                            }}
                          >
                            {suggestion.name} - {suggestion.telephone}
                          </button>
                        ))}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}

        <Card className="card new-layout">
          <CardBody className="card-body new-layout">
            <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-4">
              Dados do Cliente
            </Row>
            <Row className="w-100 mx-0">
              <Col lg={6} className="px-0 pr-lg-2">
                <Label>Nome Completo</Label>
                <FormGroup className="relative w-100">
                  <Input
                    name="name"
                    placeholder="Nome"
                    type="text"
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    onFocus={() => setShowSuggestions(true)}
                    onBlur={() =>
                      setTimeout(() => setShowSuggestions(false), 220)
                    }
                    role="presentation"
                    autocomplete="do-not-autofill"
                  />
                  {showSuggestions && suggestions.length > 0 && (
                    <div
                      className="form-control py-0 h-auto"
                      style={{ position: 'absolute', zIndex: '999999' }}
                    >
                      {suggestions.map(suggestion => (
                        <button
                          key={suggestion.telephone}
                          type="button"
                          className="my-0 py-2 d-block w-100 text-left border-0 bg-white"
                          onClick={() => {
                            setSuggestions([]);
                            setFullName(suggestion.name);
                            setTelephone(suggestion.telephone);

                            const data = formRef.current.getData();

                            formRef.current.setData({
                              ...data,
                              cpf: suggestion.cpf || '',
                              email: suggestion.email || '',
                            });
                            dispatch(saveClient(suggestion));
                          }}
                        >
                          {suggestion.name} - {suggestion.telephone}
                        </button>
                      ))}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col lg={6} className="px-0 pl-lg-2">
                <Label>WhatsApp</Label>
                <FormGroup>
                  <InputMask
                    mask={maskBuilder(telephoneV)}
                    maskChar={null}
                    value={telephoneV}
                    name="telephone"
                    placeholder="Telefone"
                    type="text"
                    onChange={e => {
                      if (
                        e.target.value.length < 15 ||
                        e.target.value[5] === '9'
                      ) {
                        setTelephone(e.target.value);
                      }
                      if (e.target.value.length === 14) {
                        searchByTelephone(e.target.value);
                      }
                      if (
                        e.target.value.length === 15 &&
                        e.target.value[5] === '9'
                      ) {
                        searchByTelephone(formatNumber(e.target.value));
                      }
                    }}
                    onPaste={e => {
                      setTelephone(
                        formatNumber(e.clipboardData.getData('Text'))
                      );
                      searchByTelephone(
                        formatNumber(e.clipboardData.getData('Text'))
                      );
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="w-100 mx-0">
              <Col lg={6} className="px-0 pr-lg-2 ">
                <Label>
                  E-mail (opcional)
                  {/* <i
                    className="las la-info-circle font-size-16 d-inline ml-2"
                    id="share-cart-info"
                  /> */}
                  {/* <UncontrolledTooltip target="share-cart-info">
                    Informe o e-mail para o cliente acompanhar o pedido
                  </UncontrolledTooltip> */}
                </Label>
                <FormGroup className="mb-2">
                  <Input
                    // onBlur={e => searchByEmail(e.target.value)}
                    name="email"
                    placeholder="E-mail"
                    type="text"
                  />
                </FormGroup>
                {/* <span className="font-weight-300 font-size-12">
                Informe o e-mail para o cliente acompanhar o pedido
              </span> */}
              </Col>
              <Col lg={6} className="px-0 pl-lg-2 ">
                <Label>
                  CPF (opcional)
                  {/* <i
                    className="las la-info-circle font-size-16 d-inline ml-2"
                    id="share-cart-info"
                  />
                  <UncontrolledTooltip target="share-cart-info">
                    Informe o CPF para cadastro no programa de fidelidade
                  </UncontrolledTooltip> */}
                </Label>
                <FormGroup className="mb-2">
                  <InputMask
                    name="cpf"
                    placeholder="CPF"
                    // onChange={e =>
                    //   e.target.value.length === 14 &&
                    //   searchByCPF(e.target.value)
                    // }
                    mask="999.999.999-99"
                    maskChar={null}
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row className="w-100 mx-0 mb-4">
          <Col className="px-0" align="end">
            <Button
              color="primary"
              size="lg"
              type="submit"
              className="new-layout-btn main"
              disabled={userSearchLoading || handleDisabled}
            >
              Avançar
            </Button>
          </Col>
        </Row>
      </Form>
      <TargetUserModal
        setShow={setShowTargetUserAlert}
        show={showTargetUserAlert}
      />
    </>
  );
};

export default ClientForm;
