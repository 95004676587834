import {
  RESET_CART,
  PATCH_CART,
  SAVE_INFORMATIONS,
  NEXT_STEP,
  SET_TAB,
  API_ERROR,
  SAVE_CLIENT,
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  REMOVE_PRODUCT,
  SEARCH_PRODUCT,
  SEARCH_PRODUCT_SUCCESS,
  CREATE_CART,
  CREATE_CART_SUCCESS,
  UPDATE_USE_CUSTOM_DELIVERY_ADDRESS,
  UPDATE_USE_CUSTOM_DELIVERY_ADDRESS_SUCCESS,
  SAVE_CUSTOM_DELIVERY_ADDRESS,
  UPDATE_DELIVERY,
  UPDATE_DELIVERY_FEE,
  FINISH_CART_LINK,
  FINISH_CART_CIELO,
  FINISH_CART_DELIVERY,
  FINISH_CART_PAGARME,
  FINISH_CART_MERCADOPAGO,
  FINISH_CART_REDE,
  FINISH_CART_LINK_SUCCESS,
  FINISH_CART_CIELO_SUCCESS,
  FINISH_CART_DELIVERY_SUCCESS,
  FINISH_CART_PAGARME_SUCCESS,
  FINISH_CART_MERCADOPAGO_SUCCESS,
  FINISH_CART_REDE_SUCCESS,
  FINISH_CART_PAGSEGURO_SUCCESS,
  FINISH_CART_PIX_SUCCESS,
  UPDATE_DELIVERY_SUCCESS,
  SAVE_CUSTOM_DELIVERY_ADDRESS_SUCCESS,
  REMOVE_PRODUCT_SUCCESS,
  SAVE_INFORMATIONS_USER,
  UPDATE_CART_INFORMATIONS,
  UPDATE_CART_INFORMATIONS_SUCCESS,
  LOAD_CART,
  LOAD_CART_SUCCESS,
  UPLOAD_TAX_COUPONS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  GENERATE_ORDER_NUMBER,
  GENERATE_ORDER_NUMBER_SUCCESS,
  UPDATE_SCHEDULED_TO,
  UPDATE_IS_SCHEDULED,
  SHOW_REGIONS_MODAL,
  SET_SHOW_CONFIRM_REGION_MODAL,
  SET_LOADING_REGIONS,
  HANDLE_ADDRESS_NOT_FOUND,
  SUBMIT_REGION,
  SET_TEMPORARY_STORE,
  SET_STORE_REGIONS,
  SET_CHOSEN_REGION,
  SET_STOCK,
  MODIFY_STOCK_ADDRESS,
  SET_DELIVERY_FLEET_REQUEST,
  SET_DELIVERY_FLEET_SUCCESS,
  SET_LOADING,
  SET_DISCOUNT,
  SET_LOADING_CHATS,
  SAVE_CHATS_SEARCH,
  SET_SHAREABLE_CART,
  CREATE_SHAREABLE_CART,
  SAVE_SHAREABLE_CART,
  SET_SALEMAN_STORE,
  SHOW_SHAREABLE_CART_MODAL,
  SET_SHAREABLE_CART_TOKEN,
  SAVE_SHAREABLE_CART_SUCCESS,
  SET_PRODUCTS,
  PATCH_CART_SUCCESS,
  FINISH_CART,
  SAVE_CART_STORE,
  SET_IGNORE_CLIENT_ADDRESS,
  SET_REGION_SUCCESS,
  SAVE_CLIENT_ADDRESS,
  SAVE_CLIENT_ADDRESS_SUCCESS,
  UPDATE_CLIENT_ADDRESS,
  UPDATE_CLIENT_ADDRESS_SUCCESS,
  MODIFY_STOCK_FLEET,
  UPDATE_FREIGHT_VEHICLE,
  SET_VEHICLE_FLEET,
  UPDATE_VEHICLE_FOR_DELIVERY,
} from './actionsTypes';

export const resetCart = () => ({
  type: RESET_CART,
});

export const setProducts = products => ({
  type: SET_PRODUCTS,
  payload: products,
});

export const setLoading = flag => ({
  type: SET_LOADING,
  payload: flag,
});

export const setShareableCartToken = token => ({
  type: SET_SHAREABLE_CART_TOKEN,
  payload: token,
});

export const setShowShareableCartModal = flag => ({
  type: SHOW_SHAREABLE_CART_MODAL,
  payload: flag,
});

export const setLoadingChats = flag => ({
  type: SET_LOADING_CHATS,
  payload: flag,
});

export const saveChatsSearch = (chats, pagination) => ({
  type: SAVE_CHATS_SEARCH,
  payload: { chats, pagination },
});

export const setIgnoreClientAddress = flag => ({
  type: SET_IGNORE_CLIENT_ADDRESS,
  payload: flag,
});

export const setDiscount = discount => ({
  type: SET_DISCOUNT,
  payload: discount,
});

export const setShareableCart = flag => ({
  type: SET_SHAREABLE_CART,
  payload: flag,
});

export const setSalesmanStore = flag => ({
  type: SET_SALEMAN_STORE,
  payload: flag,
});

export const createShareableCart = data => ({
  type: CREATE_SHAREABLE_CART,
  payload: data,
});

export const uploadTaxCoupons = files => ({
  type: UPLOAD_TAX_COUPONS,
  payload: files,
});

export const saveShareableCart = ({
  amount,
  products,
  discount,
  percentage_discount,
  max_installments,
  deliveryFleet,
  salesman_store,
  receipt_number,
  client_finish,
  giftback_amount,
}) => ({
  type: SAVE_SHAREABLE_CART,
  payload: {
    amount,
    products,
    discount,
    percentage_discount,
    max_installments,
    delivery_method: deliveryFleet,
    salesman_store,
    receipt_number,
    client_finish,
    giftback_amount,
  },
});
export const saveShareableCartSuccess = cart => ({
  type: SAVE_SHAREABLE_CART_SUCCESS,
  payload: { cart },
});

export const loadCart = ({ cart_id }) => ({
  type: LOAD_CART,
  payload: { cart_id },
});

export const generateOrderNumber = () => ({
  type: GENERATE_ORDER_NUMBER,
});

export const generateOrderNumberSuccess = order_number => ({
  type: GENERATE_ORDER_NUMBER_SUCCESS,
  payload: { order_number },
});

export const loadCartSuccess = ({ cart, products, client }) => ({
  type: LOAD_CART_SUCCESS,
  payload: { cart, products, client },
});

export const nextStep = () => ({
  type: NEXT_STEP,
});

export const setTab = orderTab => ({
  type: SET_TAB,
  payload: { orderTab },
});

export const saveClient = client => ({
  type: SAVE_CLIENT,
  payload: { client },
});

export const saveInformations = ({
  store_id,
  order_number,
  address,
  store,
}) => ({
  type: SAVE_INFORMATIONS,
  payload: { store_id, order_number, address, store },
});

export const saveInformationsAndUser = ({
  store_id,
  order_number,
  data,
  formRef,
  page_state,
}) => ({
  type: SAVE_INFORMATIONS_USER,
  payload: { store_id, order_number, data, formRef, page_state },
});

export const saveClientAddress = ({ address, page_state }) => ({
  type: SAVE_CLIENT_ADDRESS,
  payload: { address, page_state },
});

export const saveClientAddressSuccess = (address, freight) => ({
  type: SAVE_CLIENT_ADDRESS_SUCCESS,
  payload: { address, freight },
});

export const createClient = ({
  data,
  formRef,
  store_id,
  order_number,
  address,
  customAddress,
  page_state,
}) => ({
  type: CREATE_CLIENT,
  payload: {
    data,
    formRef,
    store_id,
    order_number,
    address,
    customAddress,
    page_state,
  },
});

export const createClientSuccess = client => ({
  type: CREATE_CLIENT_SUCCESS,
  payload: { client },
});

export const createCart = ({
  amount,
  salesman_store,
  discount,
  percentage_discount,
  observation,
  referral_code,
  store_id,
  order_number,
  client_id,
  products,
  address,
  totalPercentage,
  gift_box_count,
  delivery_observation,
  receipt_number,
  target_user_id,
  setHandleDisabled,
}) => ({
  type: CREATE_CART,
  payload: {
    amount,
    salesman_store,
    discount,
    percentage_discount,
    observation,
    referral_code,
    store_id,
    order_number,
    client_id,
    products,
    address,
    totalPercentage,
    gift_box_count,
    delivery_observation,
    receipt_number,
    target_user_id,
    setHandleDisabled,
  },
});

export const createCartSuccess = ({ cart, discount, totalPercentage }) => ({
  type: CREATE_CART_SUCCESS,
  payload: { cart, discount, totalPercentage },
});

export const updateCartInformations = ({
  amount,
  discount,
  percentage_discount,
  observation,
  referral_code,
  client_id,
  totalPercentage,
  gift_box_count,
  products,
  delivery_observation,
  is_suitcase_delivery,
  receipt_number,
  client_finish,
  target_user_id,
  setHandleDisabled,
}) => ({
  type: UPDATE_CART_INFORMATIONS,
  payload: {
    amount,
    discount,
    percentage_discount,
    observation,
    referral_code,
    totalPercentage,
    gift_box_count,
    client_id,
    products,
    delivery_observation,
    is_suitcase_delivery,
    receipt_number,
    client_finish,
    target_user_id,
    setHandleDisabled,
  },
});

export const updateCartInformationsSuccess = ({
  cart,
  amount,
  discount,
  percentage_discount,
  observation,
  referral_code,
  client_id,
  totalPercentage,
  shareable,
  delivery_observation,
  receipt_number,
}) => ({
  type: UPDATE_CART_INFORMATIONS_SUCCESS,
  payload: {
    cart,
    amount,
    discount,
    percentage_discount,
    observation,
    referral_code,
    client_id,
    totalPercentage,
    delivery_observation,
    shareable,
    receipt_number,
  },
});

export const addProduct = data => ({
  type: ADD_PRODUCT,
  payload: { data },
});

export const addProductSuccess = product => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: { product },
});

export const removeProduct = product => ({
  type: REMOVE_PRODUCT,
  payload: { product },
});

export const removeProductSuccess = product => ({
  type: REMOVE_PRODUCT_SUCCESS,
  payload: { product },
});

export const updateProduct = (product, amount) => ({
  type: UPDATE_PRODUCT,
  payload: { product, amount },
});

export const updateProductSuccess = (product, amount) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: { product, amount },
});

export const searchProduct = code => ({
  type: SEARCH_PRODUCT,
  payload: code,
});

export const searchProductSuccess = product => ({
  type: SEARCH_PRODUCT_SUCCESS,
  payload: product,
});

export const apiError = errorMessage => ({
  type: API_ERROR,
  payload: errorMessage,
});

export const updateDeliveryFee = deliveryFee => ({
  type: UPDATE_DELIVERY_FEE,
  payload: { deliveryFee },
});

export const updateDelivery = ({ tag, value, is_suitcase_delivery }) => ({
  type: UPDATE_DELIVERY,
  payload: { tag, value, is_suitcase_delivery },
});

export const updateVehicleForDelivery = vehicle => ({
  type: UPDATE_VEHICLE_FOR_DELIVERY,
  payload: { vehicle },
});

export const updateFreightVehicle = (freightVehicle, cart) => ({
  type: UPDATE_FREIGHT_VEHICLE,
  payload: { freightVehicle, cart },
});

export const saveCartStore = store => ({
  type: SAVE_CART_STORE,
  payload: store,
});

export const updateScheduledTo = scheduledTo => ({
  type: UPDATE_SCHEDULED_TO,
  payload: { scheduledTo },
});

export const updateIsScheduled = isScheduled => ({
  type: UPDATE_IS_SCHEDULED,
  payload: { isScheduled },
});

export const updateCustomDelivery = useUserAddress => ({
  type: UPDATE_USE_CUSTOM_DELIVERY_ADDRESS,
  payload: { useUserAddress },
});

export const UpdateCustomDeliverySuccess = useUserAddress => ({
  type: UPDATE_USE_CUSTOM_DELIVERY_ADDRESS_SUCCESS,
  payload: { useUserAddress },
});

export const saveCustomDeliveryAddress = address => ({
  type: SAVE_CUSTOM_DELIVERY_ADDRESS,
  payload: { address },
});

export const saveCustomDeliveryAddressSuccess = address => ({
  type: SAVE_CUSTOM_DELIVERY_ADDRESS_SUCCESS,
  payload: { address },
});

export const updateDeliverySuccess = ({
  productDelivery,
  useCustomDeliveryAddress,
  freight,
}) => ({
  type: UPDATE_DELIVERY_SUCCESS,
  payload: { productDelivery, useCustomDeliveryAddress, freight },
});

export const finishCart = data => ({
  type: FINISH_CART,
  payload: data,
});

export const finishCartCielo = ({ installments, cart_id, formRef }) => ({
  type: FINISH_CART_CIELO,
  payload: { installments, cart_id, formRef },
});

export const finishCartLink = ({
  amount,
  nfce,
  max_installments,
  cart_id,
  formRef,
  payment_type,
  display_payment,
  userFreight,
  is_shareable = true,
  is_payment_checkout = true,
  page_state,
  giftback_amount,
}) => ({
  type: FINISH_CART_LINK,
  payload: {
    max_installments,
    cart_id,
    formRef,
    is_shareable,
    is_payment_checkout,
    payment_type,
    display_payment,
    freight_user: userFreight,
    page_state,
    amount,
    nfce,
    giftback_amount,
  },
});

export const finishCartPagarMe = ({ installments, cart_id, formRef }) => ({
  type: FINISH_CART_PAGARME,
  payload: { installments, cart_id, formRef },
});

export const finishCartMercadoPago = ({ installments, cart_id, formRef }) => ({
  type: FINISH_CART_MERCADOPAGO,
  payload: { installments, cart_id, formRef },
});

export const finishCartRede = ({ installments, cart_id, formRef }) => ({
  type: FINISH_CART_REDE,
  payload: { installments, cart_id, formRef },
});

export const finishCartLinkSuccess = ({ link, qrcode }) => ({
  type: FINISH_CART_LINK_SUCCESS,
  payload: { link, qrcode },
});

export const finishCartCieloSuccess = ({ url: link_cielo }) => ({
  type: FINISH_CART_CIELO_SUCCESS,
  payload: { link_cielo },
});

export const finishCartPagarMeSuccess = ({ url: link_pagarme }) => ({
  type: FINISH_CART_PAGARME_SUCCESS,
  payload: { link_pagarme },
});

export const finishCartMercadoPagoSuccess = ({ url: link_mercadopago }) => ({
  type: FINISH_CART_MERCADOPAGO_SUCCESS,
  payload: { link_mercadopago },
});

export const finishCartRedeSuccess = ({ url: link_rede }) => ({
  type: FINISH_CART_REDE_SUCCESS,
  payload: { link_rede },
});

export const finishCartPagSeguroSuccess = ({ url: link_pagseguro }) => ({
  type: FINISH_CART_PAGSEGURO_SUCCESS,
  payload: { link_pagseguro },
});

export const finishCartPixSuccess = ({ qrcode }) => ({
  type: FINISH_CART_PIX_SUCCESS,
  payload: { qrcode },
});

export const finishCartDelivery = ({
  data,
  cart_id,
  formRef,
  page_state,
  userFreight,
  amount,
  nfce,
  giftback_amount,
}) => ({
  type: FINISH_CART_DELIVERY,
  payload: {
    data,
    cart_id,
    formRef,
    page_state,
    userFreight,
    amount,
    nfce,
    giftback_amount,
  },
});

export const finishCartDeliverySuccess = () => ({
  type: FINISH_CART_DELIVERY_SUCCESS,
});

export const updateClientAddress = address => ({
  type: UPDATE_CLIENT_ADDRESS,
  payload: { address },
});

export const updateClientAddressSuccess = ({ address }) => ({
  type: UPDATE_CLIENT_ADDRESS_SUCCESS,
  payload: { address },
});

export const handleAddressNotFound = (isShared, address) => ({
  type: HANDLE_ADDRESS_NOT_FOUND,
  payload: { isShared, address },
});

export const submitRegion = isShared => ({
  type: SUBMIT_REGION,
  payload: isShared,
});

export const submitRegionSuccess = isShared => ({
  type: SET_REGION_SUCCESS,
  payload: isShared,
});

export const setVehicleFleet = vehicleFleet => ({
  type: SET_VEHICLE_FLEET,
  payload: vehicleFleet,
});

export const setShowRegionsModal = flag => ({
  type: SHOW_REGIONS_MODAL,
  payload: flag,
});

export const setShowConfirmRegionModal = flag => ({
  type: SET_SHOW_CONFIRM_REGION_MODAL,
  payload: flag,
});

export const setLoadingRegions = flag => ({
  type: SET_LOADING_REGIONS,
  payload: flag,
});

export const setTemporaryStore = store => ({
  type: SET_TEMPORARY_STORE,
  payload: store,
});

export const setStoreRegions = regions => ({
  type: SET_STORE_REGIONS,
  payload: regions,
});

export const setChosenRegion = region => ({
  type: SET_CHOSEN_REGION,
  payload: region,
});

export const setStock = stock => ({
  type: SET_STOCK,
  payload: stock,
});

export const modifyStockAddress = address => ({
  type: MODIFY_STOCK_ADDRESS,
  payload: address,
});

export const modifyStockFleet = fleet => ({
  type: MODIFY_STOCK_FLEET,
  payload: fleet,
});

export const setDeliveryFleetRequest = fleet => ({
  type: SET_DELIVERY_FLEET_REQUEST,
  payload: { fleet },
});

export const setDeliveryFleetSuccess = (cart, fleet) => ({
  type: SET_DELIVERY_FLEET_SUCCESS,
  payload: { cart, fleet },
});

export const patchCart = data => ({
  type: PATCH_CART,
  payload: { data },
});

export const patchCartSuccess = data => ({
  type: PATCH_CART_SUCCESS,
  payload: { data },
});
