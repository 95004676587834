const FLEETS = [
  {
    tag: 'BEE',
    label: 'Bee',
    color: 'warning',
  },
  {
    tag: 'MOTTU',
    label: 'Mottu',
    color: 'warning',
  },
  {
    tag: 'UBER',
    label: 'Uber',
    color: 'warning',
  },
  {
    tag: 'PROPRIA',
    label: `Frota`,
    color: 'info',
  },
];
export default FLEETS;

export const deliveryFleets = [
  {
    id: 1,
    available: true,
    label: 'Bee',
    tag: 'BEE',
    icon: 'las la-motorcycle',
    value: true,
    col_padding: 'px-0 pr-lg-2',
    col_width: 2,
  },
  {
    id: 2,
    available: true,
    label: 'Mottu',
    tag: 'MOTTU',
    icon: 'las la-motorcycle',
    value: true,
    col_padding: 'px-0 pr-lg-2',
    col_width: 2,
  },
  {
    id: 5,
    available: true,
    label: 'Uber',
    tag: 'UBER',
    icon: 'las la-motorcycle',
    value: true,
    col_padding: 'px-0 pr-lg-2',
    col_width: 2,
  },
  {
    id: 3,
    available: true,
    tag: 'PROPRIA',
    icon: 'las la-motorcycle',
    value: true,
    col_padding: 'px-0 pr-lg-2',
    col_width: 2,
  },
  /*
  {
    id: 4,
    available: true,
    label: 'Retirar na Loja',
    tag: 'RETIRAR',
    icon: 'las la-box',
    value: false,
    col_padding: 'px-0 pr-lg-2',
    col_width: 2,
  },
  */
];
