import React, { useRef, useState, useMemo } from 'react';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useSelector } from 'react-redux';

import { Form } from '@unform/web';

import * as Yup from 'yup';
import { toast } from 'react-toastify';
import history from '~/services/history';
import { getRole } from '~/util/getRole';
import api from '~/services/api';
import InputSelectAsync from '~/components/Inputs/InputSelectAsync';
import useRoles from '~/hooks/useRoles';

export default function TransferSaleModal({ sale }) {
  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'digital-consultant',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const [show, setShow] = useState(false);

  const [coworkersSearched, setCoworkersSearched] = useState([]);
  const [selectedCoworker, setSelectedCoworker] = useState(null);

  const formRef = useRef(null);

  const { isStoreManager } = useRoles();

  const loadOptions = async (inputValue, cb) => {
    if (inputValue.length < 2) return;

    const { data } = await api.get(
      `${!isStoreManager ? `/${role}/users/coworkers` : `/${role}/users`}`,
      {
        params: { query: inputValue },
      }
    );

    if (data.users.length === 0) {
      return;
    }

    const coworkersSearch = data.users.map(coworker => ({
      value: coworker.id,
      label: coworker.name,
    }));

    setCoworkersSearched(prev => [...prev, ...data.users]);

    cb(coworkersSearch);
  };

  const setCoworkerSelectable = option => {
    const coworker = coworkersSearched.find(p => p.id === option.value);

    if (!coworker) return;

    const selectCoworker = {
      value: coworker.id,
      label: coworker.name,
    };

    setSelectedCoworker(selectCoworker);

    formRef.current.setFieldValue('id', selectCoworker.value);
  };

  const handleTransferToCoworker = async data => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        id: Yup.string().required('É necessário selecionar um colaborador.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      data = { ...data };

      await api.patch(`/${role}/carts/${sale.id}/transfer`, {
        user_id: data.id,
      });

      toast.success('Venda transferida com sucesso!', {
        autoClose: 5000,
      });

      history.push('/');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response && err?.response?.data?.message)
        toast.error(err?.response?.data?.message, {
          autoClose: err?.response?.data?.duration || 5000,
        });
    }
  };
  return (
    <>
      <Button
        color="secondary"
        className="new-layout-btn main mb-2 mb-lg-0"
        onClick={e => {
          e.stopPropagation();
          setShow(true);
        }}
        outline
      >
        <i className="las la-exchange-alt pl-1 mr-2 font-size-14" />
        Transferir venda
      </Button>
      <Modal isOpen={show}>
        <ModalHeader
          toggle={() => {
            setShow(false);
          }}
        >
          Transferir venda
        </ModalHeader>
        <Form onSubmit={handleTransferToCoworker} ref={formRef}>
          <ModalBody>
            <h5>Digite o nome do colaborador da sua loja para transferir.</h5>

            <FormGroup className="mt-3 mb-0" onClick={e => e.stopPropagation()}>
              <InputSelectAsync
                loadOptions={loadOptions}
                name="id"
                onChange={setCoworkerSelectable}
                placeholder="Nome do colaborador"
                type="text"
                value={selectedCoworker}
                loadingMessage={() => 'Buscando colaboradores...'}
                onBlur={() => {}}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              className="new-layout-btn main"
              type="submit"
              color="primary"
            >
              Confirmar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}
