export const getRole = (roles, preferences) => {
  let preferredRole = null;

  if (preferences) {
    preferences.forEach(preference => {
      if (roles.some(role => role.slug === preference)) {
        if (!preferredRole) {
          preferredRole = preference;
          return null;
        }
      }
      return null;
    });
  }
  const isStoreManager = roles.find(role => role.slug === 'store-manager');
  const isConsultant = roles.find(role => role.slug === 'digital-consultant');
  const isFinancier = roles.find(role => role.slug === 'financier');
  const isSalesman = roles.find(role => role.slug === 'salesman');
  const isStockist = roles.find(role => role.slug === 'stockist');
  const isAdmin = roles.find(
    role => role.slug === 'administrator' || role.slug === 'support'
  );
  const isMaster = roles.find(role => role.slug === 'master');

  if (preferredRole) return preferredRole;
  if (isConsultant) return 'digital-consultant';
  if (isFinancier) return 'financier';
  if (isAdmin) return 'administrator';
  if (isSalesman) return 'salesman';
  if (isStoreManager) return 'store-manager';
  if (isStockist) return 'stockist';
  if (isMaster) return 'master';
  return null;
};
