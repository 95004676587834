import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { formatPrice } from '~/util/format';
import Loading from '~/components/Loading';
import api from '~/services/api';
import { getRole } from '~/util/getRole';
import { CreateShortenUrl } from '~/util/shortenUrl';
import history from '~/services/history';
import { formatDate } from '~/util/formatDate';
import ShowMoreText from '~/components/ShowMoreText';

const ConfirmationSuitcaseForm = ({
  finishCartState,
  paymentData,
  sale,
  onSubmit,
  setShareableURL,
}) => {
  const cartState = useSelector(({ cart }) => cart.cart);
  const roles = useSelector(({ user }) => user.profile.roles);
  const shareableToken = useSelector(({ cart }) => cart?.shareableCartToken);
  const address = useSelector(({ cart }) => cart.address);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const [cartData, setCartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [freightValueAmount, setFreightValueAmount] = useState(null);

  const loadCartData = useCallback(async () => {
    if (!(cartState?.id || sale?.id)) return;

    const {
      data: { cart },
    } = await api.get(`${role}/carts/${cartState?.id || sale?.id}`);

    setCartData(cart);
  }, [role, cartState, sale]);

  useEffect(() => {
    setLoading(true);
    loadCartData().then(() => setLoading(false));
  }, [cartState?.id, loadCartData, sale?.id]);

  useEffect(() => {
    const loadFreight = async () => {
      setFreightValueAmount(null);

      if (!cartState.id) return;

      const {
        data: { freight },
      } = await api.get(
        `${getRole(roles)}/carts/${cartState.id}/delivery/freight/0`
      );

      setFreightValueAmount(freight || 0);
    };

    loadFreight().catch(() => setFreightValueAmount(0));
  }, [cartState, roles, address]);

  const rawShareableURL = useMemo(
    () =>
      `${window.location.origin}/meu-carrinho/?id=${
        cartState?.id || sale?.id
      }&token=${shareableToken}`,
    [shareableToken, cartState, sale]
  );

  const generateShareableUrl = useCallback(async () => {
    console.log(
      shareableToken,
      rawShareableURL,
      role,
      cartState,
      sale,
      setShareableURL
    );
    if (!(cartState?.id || sale?.id) || !shareableToken) return;

    const shortenedUrl = await CreateShortenUrl(
      role,
      rawShareableURL,
      cartState?.id || sale?.id
    );
    if (setShareableURL) setShareableURL(shortenedUrl);
  }, [shareableToken, rawShareableURL, role, cartState, sale, setShareableURL]);

  const confirmSaleRegister = async () => {
    api
      .get(`${role}/carts/${cartState?.id}/delivery/check-store-balance`)
      .catch(() => null);

    generateShareableUrl();

    if (onSubmit) {
      onSubmit();

      return;
    }

    history.push('/');
  };

  /* useEffect(() => {
    const clear = setTimeout(() => {
      if (shareableURL) history.push('/');
    }, 120000);

    return () => clearTimeout(clear);
  }, [shareableURL]); */

  if (!(sale?.id || cartData?.id) || loading)
    return (
      <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-500 text-gray-700 mb-3">
            Resumo da Malinha
          </Row>
          <Row className="mb-4">
            <Loading customClassName="loading mx-auto" />
          </Row>
        </CardBody>
      </Card>
    );

  console.log(paymentData);

  const totalAmount = Number(
    paymentData?.total || (cartData || sale)?.amount || 0
  );
  const freight = Number(
    paymentData?.freight || (cartData || sale)?.freight_user || 0
  );
  const discountGiftback = Number(
    paymentData?.useGiftback ? paymentData?.giftback_amount : 0
  );
  const finalTotal = Number(totalAmount + freight - discountGiftback);

  return (
    <>
      <Card className="card new-layout">
        <CardBody className="card-body new-layout">
          <Row className="w-100 mx-0 font-size-20 font-weight-500 mb-3">
            Resumo da Malinha
          </Row>
          <Row className="w-100 mx-0 mb-4">
            <Col
              xs={12}
              lg={6}
              className="border-lg-right pb-4 pb-lg-0 px-0 pr-lg-2"
            >
              <Row className="w-100 mx-0 font-size-16 mb-2">Cliente</Row>
              <Row className="w-100 mx-0 pb-4">
                <Col className="px-0">
                  <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                    Nome
                  </Row>
                  <Row className="mx-0 font-size-14">
                    {(cartData?.client || sale?.client)?.name}
                  </Row>
                </Col>
                <Col className="px-0">
                  <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                    Whatsapp
                  </Row>
                  <Row className="mx-0 font-size-14">
                    {(cartData?.client || sale?.client)?.telephone}
                  </Row>
                </Col>
              </Row>
              {sale && (
                <>
                  <Row className="w-100 mx-0 border-top border-lg-top-0 pt-4 pb-1 font-size-16">
                    Informações do pedido
                  </Row>
                  <Row className="w-100 mx-0 pb-0">
                    <Col xs={12} className="px-0">
                      <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                        Descrição dos produtos enviados
                      </Row>
                      <Row className="mx-0 font-size-14 mb-2">
                        <ShowMoreText
                          content={sale.delivery_observation}
                          limt={200}
                        />
                      </Row>
                    </Col>
                    <Col xs={6} className="px-0">
                      <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                        Número do pedido
                      </Row>
                      <Row className="mx-0 font-size-14">
                        {sale?.order_number}
                      </Row>
                    </Col>
                    <Col xs={6} className="px-0">
                      <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                        Data
                      </Row>
                      <Row className="mx-0 font-size-14">
                        {formatDate(sale?.created_at)}
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              {!sale && (
                <>
                  <Row className="w-100 mx-0 border-top border-lg-top-0 font-size-16 mb-1 pt-3">
                    Entrega
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col className="px-0">
                      <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                        Endereço de coleta
                      </Row>
                      <Row className="mx-0 font-size-14">
                        {finishCartState?.store?.fantasy_name ||
                          cartData?.store?.fantasy_name}
                      </Row>
                    </Col>
                    <Col className="px-0">
                      <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                        Endereço de entrega
                      </Row>
                      <Row className="mx-0 font-size-14">
                        {finishCartState?.delivery_address?.street_name ||
                          cartData?.delivery_address?.street_name}
                        ,{' '}
                        {finishCartState?.delivery_address?.number ||
                          cartData?.delivery_address?.number}{' '}
                        -{' '}
                        {finishCartState?.delivery_address?.neighborhood ||
                          cartData?.delivery_address?.neighborhood}{' '}
                        {finishCartState?.delivery_address?.city ||
                          cartData?.delivery_address?.city}{' '}
                        -{' '}
                        {finishCartState?.delivery_address?.state ||
                          cartData?.delivery_address?.state}{' '}
                        CEP{' '}
                        {finishCartState?.delivery_address?.cep ||
                          cartData?.delivery_address?.cep}
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            {sale && (
              <>
                <Col
                  xs={12}
                  lg={6}
                  className="px-0 px-lg-4 border-top border-lg-top-0 pt-4 pt-lg-0 font-size-14"
                >
                  <Row className="w-100 mx-0 font-size-16 mb-1">Frete</Row>
                  <Row className="mx-0 font-size-12 my-2 text-gray-700">
                    Precificação do frete
                  </Row>
                  <Row className="mx-0 mb-1">
                    <Row className="mx-0 text-gray-700">Custo da entrega</Row>
                    <Col align="right">
                      {sale?.delivery?.freight
                        ? formatPrice(sale?.delivery?.freight)
                        : 'Calculando...'}
                    </Col>
                  </Row>
                  <Row className="mx-0 mb-1">
                    <Row className="mx-0 text-gray-700">Custo da coleta</Row>
                    <Col align="right">
                      {sale?.return_delivery?.freight
                        ? formatPrice(sale?.return_delivery?.freight)
                        : 'Calculando...'}
                    </Col>
                  </Row>
                  <Row className="mx-0 mb-1">
                    <Row className="mx-0 text-gray-700">Custo total</Row>
                    <Col align="right">
                      {sale?.return_delivery?.freight !== null
                        ? formatPrice(
                            Number(sale?.delivery?.freight) +
                              Number(sale?.return_delivery?.freight) || 0
                          )
                        : 'Calculando...'}
                    </Col>
                  </Row>
                  <Row
                    className="w-100 mx-0 font-size-16 mb-1 border-top border-lg-top-0 pt-4 mt-4"
                    style={{ marginTop: '20px' }}
                  >
                    Pagamento
                  </Row>
                  <Row className="mx-0">
                    <Row className="mx-0 text-gray-700">Forma de pagamento</Row>
                    <Col align="right">
                      {sale?.store?.payment_gateway && 'Cartão / Pix'}
                      {sale?.store?.bnpl_payment_gateway && ' / Pix Parcelado'}
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <span className="text-gray-700">Parcelamento</span>
                    <Col align="right">
                      Em{' '}
                      {paymentData?.installments ||
                        (cartData || sale)?.max_installments ||
                        0}
                      x
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <span className="text-gray-700">Total em produtos</span>
                    <Col align="right">
                      {formatPrice(
                        paymentData?.total || (cartData || sale)?.amount || 0
                      )}
                    </Col>
                  </Row>
                  {paymentData.useGiftback && (
                    <Row className="mx-0">
                      <span className="text-gray-700">Desconto giftback</span>
                      <Col align="right">
                        <div className="font-size-14 font-weight-400 text-red">
                          -{formatPrice(paymentData.giftback_amount)}
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="mx-0 mb-2 pb-2 border-bottom">
                    <span className="text-gray-700">Frete a ser cobrado</span>
                    <Col align="right">
                      {formatPrice(
                        paymentData?.freight ||
                          (cartData || sale)?.freight_user ||
                          0
                      )}
                    </Col>
                  </Row>
                  <Row className="mx-0 font-weight-600">
                    <span>Total</span>
                    <Col align="right">{formatPrice(finalTotal)}</Col>
                  </Row>
                </Col>
              </>
            )}

            {!sale ? (
              <Col
                xs={12}
                lg={6}
                className="px-0 px-lg-4 border-top border-lg-top-0 pt-4 pt-lg-0 font-size-14"
              >
                <Row className="w-100 mx-0 font-size-16 mb-1">Frete</Row>
                <Row className="mx-0 font-size-12 my-2 text-gray-700">
                  Precificação do frete
                </Row>
                <Row className="mx-0 mb-1">
                  <Row className="mx-0 pb-4 mb-1 text-gray-700">
                    Custo da entrega
                  </Row>
                  <Col align="right">
                    {freightValueAmount ||
                    cartData?.freight ||
                    cartState?.freight
                      ? formatPrice(
                          freightValueAmount ||
                            cartData?.freight ||
                            cartState?.freight
                        )
                      : 'Calculando...'}
                  </Col>
                </Row>
                <Row className="w-100 mx-0 border-top border-lg-top-0 font-size-16 mb-1 pt-4">
                  Informações do pedido
                </Row>
                <Row className="w-100 mx-0 mb-3">
                  <Col className="px-0">
                    <Row className="mx-0 font-size-12 mt-2 mb-1 text-gray-700">
                      Descrição dos produtos enviados
                    </Row>

                    <Row className="mx-0 font-size-14">
                      <ShowMoreText
                        content={(sale || cartData)?.delivery_observation}
                        limit={200}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row className="w-100 mx-0 justify-content-between">
                  <div className="px-0">
                    <Row className="mx-0 font-size-12 mb-1 text-gray-700">
                      Número do pedido
                    </Row>
                    <Row className="mx-0 font-size-14">
                      {finishCartState?.order_number || cartData?.order_number}
                    </Row>
                  </div>
                  <div className="ml-auto px-0">
                    <Row className="mx-lg-0 ml-auto font-size-12 mb-1 text-gray-700">
                      Data
                    </Row>
                    <Row className="mx-0 pr-2 font-size-14">
                      {formatDate(
                        finishCartState?.created_at || cartData?.created_at
                      ).slice(0, 10)}
                    </Row>
                  </div>
                </Row>
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
      <Row className="w-100 mx-0">
        <Col className="px-0" align="end">
          <Button
            color="primary"
            size="lg"
            type="button"
            className="float-right w-lg-25 new-layout-btn main"
            onClick={confirmSaleRegister}
          >
            Confirmar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmationSuitcaseForm;
