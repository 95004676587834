/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import IconButton from '~/components/IconButton';
import api from '~/services/api';
import { getRole } from '~/util/getRole';

const ImportUsersModal = ({ show, setShow }) => {
  const [loading, setLoading] = useState(false);
  const spreadsheetRef = useRef(null);
  const [spreadsheet, setSpreadsheet] = useState(null);

  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const submitHandler = async () => {
    setLoading(true);

    const form = new FormData();
    form.append('file', spreadsheet);

    try {
      const { data } = await api.post(`${role}/users/import`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success(data.message);
      setSpreadsheet(null);
      setShow(false);
    } catch (e) {
      console.error(e);
      toast.error('Erro ao carregar arquivo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={show}>
      <ModalHeader toggle={() => setShow(false)}>Importar Usuários</ModalHeader>
      <ModalBody>
        <Label className="d-block">Planilha Excel</Label>
        <Row
          className={`w-100 mx-0 d-block font-weight-500 text-${
            spreadsheet ? 'success' : 'gray-700'
          }`}
        >
          <div className="d-inline">
            {spreadsheet?.name || 'Nenhum arquivo selecionado'}
          </div>
          {spreadsheet && (
            <IconButton
              id="clean-spreadsheet"
              icon="las la-times"
              color="danger"
              click={() => {
                spreadsheetRef.current.value = null;
                setSpreadsheet(null);
              }}
              tip="Limpar"
              forceTip
              fontSize={18}
              containerClass="d-inline ml-2"
            />
          )}
          {!spreadsheet ? (
            <a
              onClick={() => spreadsheetRef.current?.click()}
              className="mt-2 d-block"
            >
              Fazer Upload
            </a>
          ) : null}
        </Row>
        <input
          type="file"
          id="spreadsheet"
          accept=".xls,.xlsx"
          ref={spreadsheetRef}
          className="d-none"
          onChange={e => {
            const { files } = e.target;
            try {
              setSpreadsheet(files[0]);
            } catch (err) {
              console.log(err);
              if (err.response && err?.response?.data?.message) {
                toast.error(err?.response?.data?.message);
              }
            }
          }}
        />
        <p className="mt-5 font-size-10 d-flex">
          <span className="mt-1 mr-2">ⓘ</span>
          <span>
            O arquivo excel deve conter as seguintes colunas: Nome da Loja, Nome
            Completo Colaborador, Telefone, E-mail, Cargo, Lojas Habilitadas
            para Venda
          </span>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="new-layout-btn main"
          size="lg"
          color="primary"
          onClick={submitHandler}
          disabled={!spreadsheet || loading}
        >
          Importar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImportUsersModal;
