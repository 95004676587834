import { eventChannel } from 'redux-saga';
import Echo from 'laravel-echo';
import { client } from 'pusher-js';
import {
  takeLatest,
  put,
  all,
  call,
  spawn,
  take,
  select,
} from 'redux-saga/effects';

// User Redux States
import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';
import { SAVE_USER, UPDATE_USER } from './actionsTypes';
import { saveUserData, apiError, SetLoading } from './actions';

// Api instance
import { logoutUser } from '../auth/login/actions';

// import { LoadChatsOnLogin } from '../chat/actions';

// import { applyLayoutClasses } from '~/Util/applyLayoutClasses';
import {
  SUBSCRIBE_USER_REQUEST,
  NEW_MESSAGE_RECEIVED,
  ADD_NOTIFICATION,
  INCREMENT_UNREAD_NOTIFICATIONS_COUNT,
} from '../notifications/actionsTypes';
import {
  LoadNotificationsRequest,
  // LoadUnreadMsgsRequest,
  subscribeUserRequest,
} from '../notifications/actions';

function subscribeEvents({ echoConnection, userId }) {
  return eventChannel(emitter => {
    const msgListener = e => {
      const newMessage = e.message;

      emitter({ type: NEW_MESSAGE_RECEIVED, payload: { newMessage } });
    };

    const dangerListener = e => {
      emitter({ type: ADD_NOTIFICATION, payload: e });
      emitter({ type: INCREMENT_UNREAD_NOTIFICATIONS_COUNT });
      toast.error(e.message);
    };

    const publicNotificationsListener = e => {
      emitter({ type: ADD_NOTIFICATION, payload: e });
      emitter({ type: INCREMENT_UNREAD_NOTIFICATIONS_COUNT });
      toast.info(e.message);
    };

    const privateChannel = echoConnection
      .private(`users.${userId}`)
      .listen('MessageCreated', msgListener)
      .listen('DangerNotification', dangerListener);
    // .listen(EVENT_TYPE, listener);

    const publicChannel = echoConnection
      .channel('public')
      .listen('PublicNotification', publicNotificationsListener);

    return () => {
      privateChannel.subscription.unbind(
        privateChannel.eventFormatter.format('MessageCreated'),
        msgListener
      );
      privateChannel.subscription.unbind(
        privateChannel.eventFormatter.format('DangerNotification'),
        dangerListener
      );
      publicChannel.subscription.unbind(
        publicChannel.eventFormatter.format('PublicNotification'),
        publicNotificationsListener
      );
    };
  });
}

function* watchSubscription(echoConnection, userId) {
  const channel = yield call(subscribeEvents, {
    echoConnection,
    userId,
  });

  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* initWebsocket() {
  const echoConnection = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    forceTLS: false,
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_URL,
    enabledTransports: ['ws'],
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('@VIDDY:Token')}`,
        'X-Server':
          localStorage.getItem('@VIDDY:X-Server') &&
          `${localStorage.getItem('@VIDDY:X-Server')}`,
      },
    },
    client,
  });

  const userId = yield select(state => state.user.profile.id);

  yield all([
    takeLatest(
      SUBSCRIBE_USER_REQUEST,
      watchSubscription,
      echoConnection,
      userId
    ),
  ]);
}

function* updateUser() {
  const hasXServer = !!localStorage.getItem('@VIDDY:X-Server');
  try {
    let {
      data: { user },
    } = yield call(api.get, 'auth/me');

    const isMaster = user.roles.find(role => role.slug === 'master');

    const isSupport = user.roles.find(role => role.slug === 'support');

    if (!isMaster && (!isSupport || (isSupport && hasXServer))) {
      const {
        data: { whatsapp_number },
      } = yield call(api.get, `/utils/whatsapp-support-number`);

      const {
        data: { amount },
      } = yield call(api.get, `/cart-report/month-sales`);

      user = {
        ...user,

        whatsapp_number,
        month_sales: amount,
      };
    }

    if (!user.roles.length) {
      yield put(logoutUser());
      toast.error(
        'Você não tem permissão ainda para conseguir logar no sistema! Informe o administrador.',
        { autoClose: 5000 }
      );
      return;
    }
    yield put(saveUserData(user));
  } catch (err) {
    console.log(err);
    if (err.response && err.response?.status === 503) {
      history.push('/manutencao');
      // toast.error('O servidor encontra-se em manutenção!');
      return;
    }
    yield put(apiError(err.message));
  }
}

function* saveUser() {
  const hasXServer = !!localStorage.getItem('@VIDDY:X-Server');
  try {
    let {
      data: { user },
    } = yield call(api.get, 'auth/me');

    const isSalesman = user.roles.find(role => role.slug === 'salesman');
    const isStockist = user.roles.find(role => role.slug === 'stockist');
    const isAdmin = user.roles.find(role => role.slug === 'administrator');

    if (isSalesman && !isAdmin) {
      yield put(logoutUser());
      toast.error(
        'Este perfil de acesso está desativado, por favor procure o administrador do sistema.',
        {
          autoClose: 5000,
        }
      );
      return;
    }

    if (isStockist && !isAdmin) {
      yield put(logoutUser());
      toast.error(
        'Este perfil de acesso está desativado, por favor procure o administrador do sistema.',
        {
          autoClose: 5000,
        }
      );
      return;
    }

    const isStoreManager = user.roles.find(
      role => role.slug === 'store-manager'
    );

    const isConsultant = user.roles.find(
      role => role.slug === 'digital-consultant'
    );

    const isMaster = user.roles.find(role => role.slug === 'master');

    const isFinancier = user.roles.find(role => role.slug === 'financier');

    const isSupport = user.roles.find(role => role.slug === 'support');

    if (!isMaster && (!isSupport || (isSupport && hasXServer))) {
      const {
        data: { whatsapp_number },
      } = yield call(api.get, `/utils/whatsapp-support-number`);

      const {
        data: { amount },
      } = yield call(api.get, `/cart-report/month-sales`);

      user = {
        ...user,

        whatsapp_number,
        month_sales: amount,
      };
    }

    if (!user.roles.length) {
      yield put(logoutUser());
      toast.error(
        'Você não tem permissão ainda para conseguir logar no sistema! Informe o administrador.',
        { autoClose: 5000 }
      );
      return;
    }

    yield put(saveUserData(user));
    // applyLayoutClasses();
    if (isFinancier) {
      history.push('/financeiro');
    } else if (isMaster) {
      history.push('/master');
    } else if (isAdmin) {
      history.push('/');
    } else if (isStoreManager) {
      history.push('/');
    } else if (isStockist) {
      history.push('/estoque');
    } else if (isSalesman || isConsultant) {
      history.push('/');
    } else if (isSupport) {
      history.push('/suporte');
    }
  } catch (err) {
    console.log(err);
    if (err.response && err.response?.status === 503) {
      history.push('/manutencao');
      // toast.error('O servidor encontra-se em manutenção!');
      return;
    }
    yield put(apiError(err.message));
  }
}

export function* rehydrateInformations({ payload }) {
  if (!payload) return;
  const token = localStorage.getItem('@VIDDY:Token');
  const server = localStorage.getItem('@VIDDY:X-Server');

  const hasXServer = !!localStorage.getItem('@VIDDY:X-Server');

  if (token) {
    try {
      yield put(SetLoading(true));
      api.defaults.headers.authorization = `Bearer ${token}`;
      api.defaults.headers['X-Server'] = server;

      let {
        data: { user },
      } = yield call(api.get, 'auth/me');

      const isMaster = user.roles.find(role => role.slug === 'master');

      const isSupport = user.roles.find(role => role.slug === 'support');

      if (!isMaster && (!isSupport || (isSupport && hasXServer))) {
        const {
          data: { subscriber_name },
        } = yield call(api.get, `/utils/available-delivery-services`);

        const {
          data: { whatsapp_number },
        } = yield call(api.get, `/utils/whatsapp-support-number`);

        const {
          data: { amount },
        } = yield call(api.get, `/cart-report/month-sales`);

        user = {
          ...user,
          subscriber_name,
          whatsapp_number,
          month_sales: amount,
        };
      }

      yield put(saveUserData(user));
      if (history.location.pathname === '/manutencao') {
        const isSalesman = user.roles.find(role => role.slug === 'salesman');

        const isStockist = user.roles.find(role => role.slug === 'stockist');

        const isStoreManager = user.roles.find(
          role => role.slug === 'store-manager'
        );

        const isConsultant = user.roles.find(
          role => role.slug === 'digital-consultant'
        );

        const isFinancier = user.roles.find(role => role.slug === 'financier');

        if (isStoreManager) {
          history.push('/sua-loja');
        } else if (isStockist) {
          history.push('/estoque');
        } else if (isSalesman || isConsultant) {
          history.push('/');
        } else if (isMaster) {
          history.push('/master');
        } else if (isFinancier) {
          history.push('/financeiro');
        }
      }

      // yield put(LoadChatsOnLogin());
      // yield put(LoadUnreadMsgsRequest());
      yield put(LoadNotificationsRequest());

      yield spawn(initWebsocket);
      // yield put(subscriberUserRequest());
      yield put(subscribeUserRequest());
    } catch (err) {
      console.log(err);
      window.location.reload();
      if (err.response && err.response?.status === 503) return;
      yield put(logoutUser());
    }
  }
}

export default all([
  takeLatest('persist/REHYDRATE', rehydrateInformations),
  takeLatest(SAVE_USER, saveUser),
  takeLatest(UPDATE_USER, updateUser),
]);
