export const getRoles = roles => {
  const xServer = localStorage.getItem('@VIDDY:X-Server');
  return {
    isSalesman: roles.some(r => r.slug === 'salesman'),
    isStockist: roles.some(r => r.slug === 'stockist'),
    isStoreManager: roles.some(r => r.slug === 'store-manager'),
    isAdmin: roles.some(
      r => r.slug === 'administrator' || (r.slug === 'support' && xServer)
    ),
    isSupport: roles.some(r => r.slug === 'support'),
    isFinancier: roles.some(r => r.slug === 'financier'),
    isMaster: roles.some(r => r.slug === 'master'),
    isConsultant: roles.some(r => r.slug === 'digital-consultant'),
  };
};
