import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row, Collapse } from 'reactstrap';
import IconButton from '~/components/IconButton';
import { LG } from '~/util/breakpointWidths';
import { defineExpandedState } from '~/util/newLayoutHelpers';
import ShowMoreText from '~/components/ShowMoreText';
import CartManagementButtons from '../CartManagementButtons';
// import { copyStringToClipboard } from '~/Util/copyToClipboard';

export default function CartMainInfoCard({
  cart,
  setData,
  setProductsData,
  // shareableURL,
}) {
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [expanded, setExpanded] = useState(defineExpandedState(windowSize, LG));

  const extraInfoCount = useMemo(() => {
    let count = 0;
    if (cart.referral_code) {
      count += 1;
    }
    if (cart.observation) {
      count += 1;
    }
    if (cart.cancellation_reason) {
      count += 1;
    }
    return count;
  }, [cart]);

  const showButtons = useMemo(() => {
    if (windowSize >= LG && expanded) {
      return true;
    }
    return false;
  }, [windowSize, expanded]);

  const showExtraRow = useMemo(() => {
    if (extraInfoCount > 0) {
      return true;
    }
    return false;
  }, [extraInfoCount]);

  const extraRowColWidth = useMemo(() => {
    if (extraInfoCount > 0) {
      return 12 / extraInfoCount;
    }
    return 0;
  }, [extraInfoCount]);

  useEffect(() => {
    setExpanded(defineExpandedState(windowSize, LG));
  }, [windowSize]);

  return (
    <Card className="new-layout card">
      <CardBody className="new-layout card-body px-3 py-3">
        <Row className="w-100 mx-0 justify-content-between">
          <Col
            className="w-100 font-size-16 font-weight-600 d-flex align-items-start px-0"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <Row
              className={`w-100 ${
                expanded ? 'mt-lg-4' : ''
              } ml-0 ml-lg-2 mr-0 cursor-pointer justify-content-between align-items-center`}
            >
              <div className="px-0 mb-0">Pedido #{cart.order_number}</div>
              {showButtons && (
                <div className="d-none d-lg-block px-2">
                  <CartManagementButtons
                    cart={cart}
                    setData={setData}
                    setProductsData={setProductsData}
                  />
                </div>
              )}
            </Row>
            <IconButton
              icon={`las la-${expanded ? 'angle-up' : 'angle-right'}`}
              click={() => {
                setExpanded(!expanded);
              }}
              color="gray-700"
            />
          </Col>
        </Row>
        <Collapse isOpen={expanded}>
          <hr className="d-none d-lg-flex ml-0 ml-2 mr-4" />

          <Row className="w-100 ml-0 ml-lg-2 pr-4 mb-3 mt-3 mt-lg-4">
            <Col className="pl-0 mb-2 mb-lg-0 d-lg-none" xs={12}>
              <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                Loja
              </div>
              <div className="font-size-14 font-weight-400 text-black d-block">
                {cart.store.fantasy_name}
              </div>
            </Col>
            <Col className="pl-0 mb-2 mb-lg-0" xs={6} lg={3}>
              <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                Criado por
              </div>
              <div className="font-size-14 font-weight-400 text-black d-block">
                {cart.created_by.name}
              </div>
            </Col>
            <Col className="pl-0 mb-2 mb-lg-0" xs={6} lg={3}>
              <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                Gerenciado por
              </div>
              <div className="font-size-14 font-weight-400 text-black d-block">
                {cart.handled_by.name}
              </div>
            </Col>
            <Col className="px-0 mb-2 mb-lg-0" xs={6} lg={3}>
              <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                Criado em
              </div>
              <div className="font-size-14 font-weight-400 text-black d-block">
                {cart.formatted_created_at}
              </div>
            </Col>
            <Col className="px-0 mb-2 mb-lg-0" xs={6} lg={3}>
              <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                Última atualização
              </div>
              <div className="font-size-14 font-weight-400 text-black d-block">
                {cart.formatted_updated_at}
              </div>
            </Col>
            {cart.cancellation_reason && (
              <Col xs={6} lg={3} className="px-0 mb-2 mb-lg-0 mt-3">
                <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                  Motivo do Cancelamento
                </div>
                <div className="font-size-14 font-weight-400 text-black d-block">
                  {cart.cancellation_reason}
                </div>
              </Col>
            )}
          </Row>
          {cart.is_suitcase_delivery ? (
            <>
              <hr className="d-flex ml-0 ml-lg-2 mr-lg-4 mb-3" />
              <Row className="w-100 ml-0 ml-lg-2 mr-4 mb-lg-2">
                <Col className="px-0 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                    Descrição dos produtos enviados
                  </div>
                  <div className="font-size-14 font-weight-400 text-black d-block mt-1">
                    <ShowMoreText
                      content={cart.delivery_observation}
                      limit={200}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : null}
          {/* {shareableURL && !cart.is_payment_checkout && (
            <Row className="w-100 mx-0 mb-4">
              <Col className="px-0 mb-2 mb-lg-0">
                <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                  Link de Compartilhamento
                </div>
                <div className="font-size-14 font-weight-400 text-black d-block">
                  <Row className="w-100 mx-0 d-flex flex-row align-items-center">
                    <a className="d-inline mr-2" href={shareableURL}>
                      {shareableURL}
                    </a>
                    <IconButton
                      id="copy-shareable-url-btn"
                      icon="las la-copy"
                      color="info"
                      tip="Copiar"
                      // fontSize={20}
                      containerClass="h-100 d-flex flex-column justify-content-end d-inline"
                      forceTip
                      click={() =>
                        copyStringToClipboard(shareableURL, 'Link copiado!')
                      }
                    />
                  </Row>
                </div>
              </Col>
            </Row>
          )} */}
          {showExtraRow && (
            <Row className="w-100 mx-0 mb-4">
              {cart.referral_code && (
                <Col
                  lg={extraRowColWidth === 4 ? 3 : extraRowColWidth}
                  className="px-0 mb-2 mb-lg-0"
                >
                  <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                    Código de indicação
                  </div>
                  <div className="font-size-14 font-weight-400 text-black d-block">
                    {cart.referral_code.code}
                  </div>
                </Col>
              )}
              {cart.observation && (
                <Col lg={extraRowColWidth} className="px-0 mb-2 mb-lg-0">
                  <div className="font-size-14 font-weight-600 text-gray-600 d-block">
                    Observação
                  </div>
                  <div className="font-size-14 font-weight-400 text-black d-block">
                    {cart.observation}
                  </div>
                </Col>
              )}
            </Row>
          )}
        </Collapse>
      </CardBody>
    </Card>
  );
}
