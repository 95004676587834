import React from 'react';
import { Badge } from 'reactstrap';
import { SEARCHING } from '~/util/deliveryStatuses';
import { capitalize } from '~/util/format';
import { SaleAlertBadge } from './SaleMessageBadge';

export default function SaleStatusBadge({
  cart,
  cartDetails,
  extraClass,
  mobile,
}) {
  const formatBadge = () => {
    if (cart.status_text === 'Aberto') {
      return {
        text: 'Carrinho Aberto',
        class: 'badge-info',
      };
    }
    if (cart.status_text === 'Aguardando cliente') {
      return {
        text: 'Aguardando Cliente',
        class: 'badge-yellow',
      };
    }
    if (cart.status_text === 'Em pagamento') {
      return {
        text: 'Aguardando Pagamento',
        class: 'badge-yellow',
      };
    }
    if (cart.status_text === 'Pedido reiniciado por vendedor') {
      return {
        text: 'Carrinho Reiniciado',
        class: 'badge-info',
      };
    }
    if (cart.status_text === 'Esperando por aprovação do financeiro.') {
      return {
        text: 'Aguardando Aprovação do Financeiro',
        class: 'badge-yellow',
      };
    }
    if (cart.status_text === 'Pagamento por transferência não aprovado') {
      return {
        text: 'Pagamento Reprovado pelo Financeiro',
        class: 'badge-red',
      };
    }
    if (cart.status_text === 'Aguardando estoquista') {
      return {
        text:
          cart.payment_type === 201 ||
          cart.payment_type === 202 ||
          cart.payment_type === 203 ||
          cart.payment_type === 401
            ? 'Aguardando Separação'
            : 'Pagamento Confirmado',
        class: 'badge-authorized',
      };
    }
    if (cart.status_text === 'Pagamento autorizado com baixo risco de fraude') {
      return {
        text: 'Aguardando Estoquista - Risco de Fraude Baixo',
        class: 'badge-yellow',
      };
    }
    if (cart.status_text === 'Encaminhado por estoquista') {
      if (cart.is_for_delivery && cart?.delivery?.status !== 'CANCELED')
        return {
          text: cart?.delivery?.worker_name
            ? 'Entregador em Rota'
            : 'Buscando Entregador',
          class: 'badge-authorized',
        };

      if (cart.is_for_delivery && cart?.delivery?.status === 'CANCELED')
        return {
          text: 'Aguardando Entrega',
          class: 'badge-yellow',
        };

      return {
        text: 'Aguardando Retirada',
        class: 'badge-yellow',
      };
    }
    if (cart.status_text === 'Aguardando estorno') {
      return {
        text: 'Estorno Solicitado',
        class: 'badge-yellow',
      };
    }
    if (cart.status_text === 'Pedido devolvido') {
      return {
        text: 'Pedido devolvido',
        class: 'badge-gray',
      };
    }
    if (
      !cart.is_suitcase_delivery &&
      cart.status_text === 'Entregue' &&
      cart.return_delivery
    ) {
      if (cart.return_delivery?.status === SEARCHING) {
        return {
          text: 'Buscando entregador',
          class: 'badge-authorized',
        };
      }

      return {
        text: 'Entregador em rota',
        class: 'badge-authorized',
      };
    }
    if (cart.status_text === 'Entregue') {
      return {
        text: 'Entregue',
        class: 'badge-green',
      };
    }
    if (cart.status_text === 'Cancelado') {
      return {
        text: 'Cancelado',
        class: 'badge-red',
      };
    }
    if (cart.status_text === 'Chargeback Solicitado') {
      return {
        text: 'Chargeback Solicitado',
        class: 'badge-red',
      };
    }
    if (cart.status_text === 'Pagamento rejeitado por risco de fraude') {
      return {
        text: 'Pagamento Rejeitado - Risco de Fraude',
        class: 'badge-red',
      };
    }
    if (
      cart.status_text === 'Pagamento rejeitado automaticamente pelo serviço'
    ) {
      return {
        text: 'Pagamento rejeitado automaticamente pelo serviço',
        class: 'badge-red',
      };
    }
    if (cart.status_text === 'Pagamento rejeitado por alto risco de fraude') {
      return {
        text: 'Transação recusada por alto risco de fraude',
        class: 'badge-red',
      };
    }
    if (cart.status_text === 'Pagamento rejeitado por médio risco de fraude') {
      return {
        text: 'Transação recusada por médio risco de fraude',
        class: 'badge-red',
      };
    }
    if (cart.status_text === 'Pagamento negado pela fornecedora do cartão') {
      return {
        text: 'Transação negada pela operadora do cartão',
        class: 'badge-red',
      };
    }
    if (cart.status_text === 'Limite estornado para cliente') {
      return {
        text: 'Limite estornado para cliente',
        class: 'badge-red',
      };
    }
    if (cart.status_text === 'Pagamento rejeitado pelo anti-fraude') {
      return {
        text: 'Pagamento rejeitado pelo anti-fraude',
        class: 'badge-red',
      };
    }

    if (cart.status_text === 'Coleta realizada' && cart.amount !== null) {
      return {
        text: 'Aguardando pagamento',
        class: 'badge-yellow',
      };
    }

    if (cart.status_text === 'Coleta realizada') {
      return {
        text: 'Coleta realizada',
        class: 'badge-gray',
      };
    }

    if (cart.status_text === 'Malinha entregue' && cart.return_delivery) {
      return {
        text:
          cart.return_delivery?.status === SEARCHING
            ? 'Buscando entregador'
            : 'Entregador em rota',
        class: 'badge-authorized',
      };
    }

    if (cart.status_text === 'Malinha entregue') {
      return {
        text: 'Malinha entregue',
        class: 'badge-cyan',
      };
    }

    if (cart.status_text === 'Pedido finalizado') {
      return {
        text: 'Pedido finalizado',
        class: 'badge-green',
      };
    }

    if (cart.status_text === 'Pagamento rejeitado') {
      return {
        text: 'Pagamento não aprovado',
        class: 'badge-red',
      };
    }

    if (cart.status_text === 'Análise de Fraude') {
      return {
        text: 'Análise de Fraude',
        class: 'badge-gray',
      };
    }

    if (cart.status_text === 'Pedido em Processamento') {
      return {
        text: 'Pedido em Processamento',
        class: 'badge-orange',
      };
    }

    if (cart.status_text === 'Análise Reprovada') {
      return {
        text: 'Análise Reprovada',
        class: 'badge-red',
      };
    }

    if (cart.status_text === 'Análise Aprovada') {
      return {
        text: 'Análise Aprovada',
        class: 'badge-green',
      };
    }

    return {
      text: cart.status_text,
      class: 'badge-yellow',
    };
  };

  const badge_info = formatBadge();

  return (
    <>
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${
          mobile ? 'd-lg-none' : ''
        }`}
        style={
          mobile
            ? {
                width: '100vw !important',
                position: 'absolute',
                top: '64px',
                left: '0px',
                right: '0px',
              }
            : {}
        }
      >
        <Badge
          className={`sale-status-badge ${badge_info?.class} ${
            cartDetails && 'cart-details'
          } text-center`}
        >
          {badge_info?.text}
        </Badge>
        {cart?.status_text === 'Encaminhado por estoquista' &&
        cart?.status_text !== 'Malinha entregue' &&
        cart?.delivery?.status !== 'CANCELED' &&
        cart?.delivery?.worker_name &&
        !cartDetails ? (
          <p
            className="mb-0 d-none d-lg-block"
            style={{
              marginTop: '10px',
              fontSize: '12px',
              color:
                badge_info.class !== 'badge-yellow' ? '#967ada' : '#eca120',
            }}
          >
            {capitalize(cart?.delivery?.worker_name)}
          </p>
        ) : null}
        {(cart?.status_text === 'Malinha entregue' ||
          cart?.status_text === 'Entregue') &&
        cart?.return_delivery &&
        cart?.return_delivery?.status !== 'CANCELED' &&
        cart?.return_delivery?.worker_name &&
        !cartDetails ? (
          <p
            className="mb-0 d-none d-lg-block"
            style={{
              marginTop: '10px',
              fontSize: '12px',
              color:
                badge_info.class !== 'badge-yellow' ? '#967ada' : '#eca120',
            }}
          >
            {cart?.return_delivery?.worker_name &&
              capitalize(cart?.return_delivery?.worker_name)}
          </p>
        ) : null}
      </div>
      {cart?.status === 0 && cart.status_text !== 'Aberto' && (
        <div
          className={`font-size-14 font-weight-600 text-gray-600 d-block mb-3 ${extraClass} ${
            cartDetails && mobile ? 'd-lg-none' : ''
          }`}
        >
          {cart.acquirer_response_message && (
            <p className="mb-3 text-center font-weight-500">
              Erro {cart.acquirer_response_message}
            </p>
          )}
          {cart.guidance_response_message && (
            <p className="mb-2">
              <SaleAlertBadge
                color="success"
                backgroundColor="rgba(96, 176, 93, 0.15)"
              >
                <b>Orientação:</b> {cart.guidance_response_message}
              </SaleAlertBadge>
            </p>
          )}
          {cart.attention_response_message && (
            <p className="mb-2">
              <SaleAlertBadge
                color="yellow"
                backgroundColor="rgba(236, 161, 32, 0.15)"
              >
                <b>Atenção:</b> {cart.attention_response_message}
              </SaleAlertBadge>
            </p>
          )}

          {cart.anti_fraud_detail &&
            cart.anti_fraud_detail !== 'Operação realizada com sucesso' && (
              <p>Motivo antifraude: {cart.anti_fraud_detail}</p>
            )}
        </div>
      )}
    </>
  );
}
