import { takeLatest, put, all, call, select } from 'redux-saga/effects';

// Toast to send error/success message
import { toast } from 'react-toastify';

// Cart Redux States
import api from '~/services/api';
import history from '~/services/history';
import { getRole } from '~/util/getRole';
import {
  CREATE_CLIENT,
  ADD_PRODUCT,
  FINISH_CART_CIELO,
  FINISH_CART_PAGARME,
  FINISH_CART_MERCADOPAGO,
  FINISH_CART_REDE,
  FINISH_CART_PAGSEGURO,
  FINISH_CART_DELIVERY,
  CREATE_CART,
  // UPDATE_DELIVERY,
  SAVE_CUSTOM_DELIVERY_ADDRESS,
  UPDATE_USE_CUSTOM_DELIVERY_ADDRESS,
  REMOVE_PRODUCT,
  UPDATE_PRODUCT,
  SAVE_INFORMATIONS_USER,
  UPDATE_CART_INFORMATIONS,
  LOAD_CART,
  GENERATE_ORDER_NUMBER,
  HANDLE_ADDRESS_NOT_FOUND,
  SET_DELIVERY_FLEET_REQUEST,
  SAVE_SHAREABLE_CART,
  PATCH_CART,
  FINISH_CART,
  SUBMIT_REGION,
  SET_IGNORE_CLIENT_ADDRESS,
  FINISH_CART_LINK,
  SAVE_CLIENT_ADDRESS,
  UPDATE_CLIENT_ADDRESS,
  SET_VEHICLE_FLEET,
} from './actionsTypes';
import {
  createClientSuccess,
  createCartSuccess,
  apiError,
  addProductSuccess,
  finishCartCieloSuccess,
  finishCartPagarMeSuccess,
  finishCartMercadoPagoSuccess,
  finishCartRedeSuccess,
  resetCart,
  saveInformations,
  // updateDeliverySuccess,
  saveCustomDeliveryAddressSuccess,
  UpdateCustomDeliverySuccess,
  removeProductSuccess,
  updateProductSuccess,
  updateCartInformationsSuccess,
  loadCartSuccess,
  generateOrderNumberSuccess,
  setShowRegionsModal,
  // handleAddressNotFound,
  setLoadingRegions,
  setStoreRegions,
  setChosenRegion,
  setDeliveryFleetSuccess,
  // setShowShareableCartModal,
  setShareableCartToken,
  saveShareableCartSuccess,
  patchCartSuccess,
  patchCart,
  setShowConfirmRegionModal,
  saveCartStore,
  setIgnoreClientAddress,
  updateDelivery,
  finishCartPixSuccess,
  submitRegionSuccess,
  saveClientAddressSuccess,
  updateClientAddressSuccess,
  nextStep,
  handleAddressNotFound,
  updateFreightVehicle,
  finishCartPagSeguroSuccess,
} from './actions';

import { patchCart as patchShareableCart } from '../shareable_cart/actions';

// Services instance

function* getGeneratedOrderNumber() {
  try {
    const {
      data: { order_number },
    } = yield call(api.get, 'utils/generate-order-number');

    yield put(generateOrderNumberSuccess(order_number));
  } catch (err) {
    yield put(apiError(err.message));
  }
}

function* loadCart({ payload: { cart_id } }) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    const [
      {
        data: { cart },
      },
      {
        data: { products },
      },
    ] = yield all([
      call(api.get, `${getRole(roles)}/carts/${cart_id}`),
      call(api.get, `${getRole(roles)}/carts/${cart_id}/products`),
    ]);

    if (!cart.is_for_delivery) {
      const {
        data: { store },
      } = yield call(api.get, `/stores/${cart.store.id}`);

      yield put(saveCartStore(store));
    }

    const parsedProducts = products.map(product => ({
      product_id: product.id,
      name: product.name,
      code: product.code,
      prod_amount: product.pivot.prod_amount,
      gift_box_index: product.pivot.gift_box_index,
      gift: product.pivot.gift,
      value: product.pivot.value,
    }));

    if (cart.client) {
      const {
        data: { client },
      } = yield call(api.get, `clients/${cart.client.id}`);

      if (!client.address) {
        yield put(setIgnoreClientAddress(true));
      }

      yield put(loadCartSuccess({ cart, products: parsedProducts, client }));
    } else {
      yield put(
        loadCartSuccess({ cart, products: parsedProducts, client: null })
      );
    }
  } catch (err) {
    yield put(apiError(err.message));
  }
}
function* IgnoreClientAddressAsyncSideEffects({
  payload: ignoreClientAddress,
}) {
  const cart = yield select(({ cart: Cart }) => Cart.cart);

  if (cart) {
    yield put(updateDelivery(!ignoreClientAddress));
  }
}

function* CreateCart({
  payload: {
    amount,
    discount,
    percentage_discount,
    observation,
    referral_code,
    totalPercentage,
    gift_box_count,
    delivery_observation,
    receipt_number,
    target_user_id,
    setHandleDisabled,
  },
}) {
  const roles = yield select(({ user }) => user.profile.roles);
  const ignoreClientAddress = yield select(
    ({ cart }) => cart.ignoreClientAddress
  );
  const client = yield select(({ cart }) => cart.client);
  const deliveryFleet = yield select(({ cart }) => cart.deliveryFleet);
  const isForDelivery = yield select(({ cart }) => cart.is_for_delivery);
  const vehicle = yield select(({ cart }) => cart.vehicle);
  const store = yield select(({ cart }) => cart.selectedStore);
  const tax_coupon = yield select(({ cart }) => cart.files);
  const is_suitcase_delivery = yield select(
    ({ cart }) => cart.is_suitcase_delivery
  );

  try {
    const {
      data: { order_number },
    } = yield call(api.get, 'utils/generate-order-number');

    const {
      data: { is_available },
    } = yield call(api.post, 'utils/check-order-number-availability', {
      order_number,
    });

    if (!is_available) {
      toast.error('Número de pedido já existe');
      return;
    }

    const data = {
      amount,
      discount: Number(0),
      percentage_discount,
      observation,
      referral_code,
      client_id: client ? client.id : null,
      store_id: store.value ?? null,
      order_number,
      payment_type: 0,
      is_for_delivery: isForDelivery,
      free_freight: false,
      use_custom_delivery_address: false,
      freight: null,
      has_custom_freight: false,
      gift_box_count,
      delivery_observation,
      freightVehicle: null,
      ignore_client_address: ignoreClientAddress || false,
      is_suitcase_delivery,
      target_user_id: target_user_id || '',
    };

    if (isForDelivery) {
      data.delivery_method = deliveryFleet;
      data.delivery_vehicle = vehicle;
    }

    if (receipt_number) {
      data.nfce = {
        number: receipt_number,
      };
    }

    const {
      data: { cart },
    } = yield call(api.post, `${getRole(roles)}/carts`, data);

    if (tax_coupon) {
      for (let i = 0; i < tax_coupon.length; i += 1) {
        const formData = new FormData();
        formData.append('tax_coupon', tax_coupon[i]);
        yield call(
          api.post,
          `${getRole(roles)}/carts/${cart.id}/attach-tax-coupon`,
          formData
        );
      }
    }

    const {
      data: { cart: updatedCart },
    } = yield call(api.get, `${getRole(roles)}/carts/${cart.id}`);

    yield put(
      createCartSuccess({ cart: updatedCart, discount, totalPercentage })
    );

    if (ignoreClientAddress) {
      // yield put(setIgnoreClientAddress(true));
      yield put(updateDelivery(!ignoreClientAddress));
    }

    if (deliveryFleet === 'RETIRAR') yield put(nextStep());
    /* if (
      (client?.address?.has_coordinates && deliveryFleet === 'BEE') ||
      deliveryFleet !== 'BEE'
    ) {
      console.log('! - 1')
      // yield put(nextStep());
    } */
    // toast.success('Carrinho criado!');
  } catch (err) {
    if (err.response?.data?.message) toast.error(err.response?.data?.message);

    yield put(apiError(err.message));

    console.log(err?.response?.data?.tax_coupon);

    if (
      err?.response?.data?.tax_coupon &&
      err?.response?.data?.tax_coupon[0] ===
        'O campo tax coupon deve ser um arquivo do tipo: jpg, jpeg, png, gif, bmp, tiff, tif, svg.'
    )
      toast.error(
        'O comprovante deve ser um arquivo do tipo: jpg, jpeg, png, gif, bmp, tiff, tif ou svg.'
      );

    if (err?.response?.data?.amount) toast.error(err.response.data.amount[0]);

    if (err?.response?.data?.referral_code)
      toast.error(err.response.data.referral_code[0]);
  } finally {
    setHandleDisabled(false);
  }
}

function* createClient({
  payload: { data, formRef, order_number, store_id, page_state },
}) {
  const roles = yield select(({ user }) => user.profile.roles);
  const showRegionsModal = select(({ cart }) => cart.showRegionsModal);

  try {
    const {
      data: { client },
    } = yield call(api.post, `${getRole(roles)}/clients`, { ...data, type: 1 });

    yield put(createClientSuccess(client));

    // toast.success('Cliente cadastrado com sucesso!');

    yield put(saveInformations({ store_id, order_number }));

    if (showRegionsModal) {
      yield put(setShowRegionsModal(false));
    }

    yield put(nextStep());
  } catch (err) {
    yield put(apiError(err.message));
    const { message, cpf, email, telephone } = err.response.data;
    if (cpf) formRef.current.setFieldError('cpf', cpf);
    if (email) formRef.current.setFieldError('email', email);
    if (telephone) formRef.current.setFieldError('telephone', telephone);
    if (message) toast.error(message);
  } finally {
    page_state.setHandleDisabled(false);
  }
}

function* saveInformationsUser({
  payload: { data, formRef, order_number, store_id, page_state },
}) {
  const roles = yield select(({ user }) => user.profile.roles);
  const showRegionsModal = yield select(({ cart }) => cart.showRegionsModal);

  try {
    const Client = yield select(({ cart }) => cart.client);
    const Cart = yield select(({ cart }) => cart.cart);

    const {
      data: { client },
    } = yield call(api.put, `${getRole(roles)}/clients/${Client.id}`, data);

    if (Cart) {
      yield put(
        saveInformations({
          store_id,
          order_number,
        })
      );
    }

    yield put(createClientSuccess(client));
    yield put(saveInformations({ store_id, order_number }));

    if (showRegionsModal) {
      yield put(setShowRegionsModal(false));
    }

    yield put(nextStep());
  } catch (err) {
    const { message, cpf, email, telephone } = err.response.data;
    if (cpf) formRef.current.setFieldError('cpf', cpf);
    if (email) formRef.current.setFieldError('email', email);
    if (telephone) formRef.current.setFieldError('telephone', telephone);
    if (message) toast.error(message);
  } finally {
    page_state.setHandleDisabled(false);
  }
}

function* addProduct({ payload: { data } }) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    const Cart = yield select(({ cart }) => cart.cart);

    if (Cart) {
      yield call(api.post, `${getRole(roles)}/carts/${Cart.id}/products`, {
        products: [data],
      });
    }

    yield put(addProductSuccess(data));
  } catch (err) {
    yield put(apiError(err.message));
  }
}

function* removeProduct({ payload: { product } }) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    const Cart = yield select(({ cart }) => cart.cart);

    if (Cart) {
      yield call(
        api.delete,
        `${getRole(roles)}/carts/${Cart.id}/products/${product.product_id}`
      );
    }

    yield put(removeProductSuccess(product));
  } catch (err) {
    yield put(apiError(err.message));
  }
}

function* updateProduct({ payload: { product, amount } }) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    const Cart = yield select(({ cart }) => cart.cart);

    if (Cart) {
      yield call(
        api.put,
        `${getRole(roles)}/carts/${Cart.id}/products/${product.product_id}`,
        {
          prod_amount: amount,
          gift: product.gift,
          value: Number(product.value),
        }
      );
    }

    product = {
      ...product,
      prod_amount: amount,
    };

    yield put(updateProductSuccess(product));
  } catch (err) {
    yield put(apiError(err.message));
  }
}

function* finishCartCielo({ payload: { installments, cart_id, formRef } }) {
  const roles = yield select(({ user }) => user.profile.roles);
  const link_cielo = yield select(({ cart }) => cart.link_cielo);

  try {
    const {
      data: {
        cart: {
          payment_process: { url },
        },
      },
    } = yield call(api.post, `${getRole(roles)}/carts/${cart_id}/finalize`, {
      installments,
      payment_type: 101,
    });
    toast.success(link_cielo ?? 'Link Gerado');
    toast.success(link_cielo ? 'Link atualizado!' : 'Venda registrada');
    yield put(finishCartCieloSuccess({ url }));
    formRef.current.setFieldValue('link_cielo', url);
  } catch (err) {
    yield put(apiError(err.message));
    toast.error(err.response.data.message);
  }
}

function* finishCartPagarMe({ payload: { installments, cart_id, formRef } }) {
  const roles = yield select(({ user }) => user.profile.roles);
  const link_pagarme = yield select(({ cart }) => cart.link_pagarme);
  const ignoreClientAddress = yield select(
    ({ cart }) => cart.ignoreClientAddress
  );

  try {
    const {
      data: {
        cart: {
          payment_link: { url },
        },
      },
    } = yield call(api.post, `${getRole(roles)}/carts/${cart_id}/finalize`, {
      ignore_client_address: ignoreClientAddress,
      installments,
      payment_type: 107,
    });
    toast.success(link_pagarme ?? 'Link Gerado');
    toast.success(link_pagarme ? 'Link atualizado!' : 'Venda registrada');
    yield put(finishCartPagarMeSuccess({ url }));
    formRef.current.setFieldValue('link_pagarme', url);
  } catch (err) {
    yield put(apiError(err.message));
    toast.error(err.response.data.message);
  }
}

function* finishCartMercadoPago({
  payload: { installments, cart_id, formRef },
}) {
  const roles = yield select(({ user }) => user.profile.roles);
  const link_mercadopago = yield select(({ cart }) => cart.link_mercadopago);
  const ignoreClientAddress = yield select(
    ({ cart }) => cart.ignoreClientAddress
  );

  try {
    const {
      data: {
        cart: {
          payment_link: { url },
        },
      },
    } = yield call(api.post, `${getRole(roles)}/carts/${cart_id}/finalize`, {
      ignore_client_address: ignoreClientAddress,
      installments,
      payment_type: 109,
    });
    toast.success(link_mercadopago ?? 'Link Gerado');
    toast.success(link_mercadopago ? 'Link atualizado!' : 'Venda registrada');
    yield put(finishCartMercadoPagoSuccess({ url }));
    formRef.current.setFieldValue('link_mercadopago', url);
  } catch (err) {
    yield put(apiError(err.message));
    toast.error(err.response.data.message);
  }
}

function* finishCartRede({ payload: { installments, cart_id, formRef } }) {
  const roles = yield select(({ user }) => user.profile.roles);
  const link_rede = yield select(({ cart }) => cart.link_rede);
  const ignoreClientAddress = yield select(
    ({ cart }) => cart.ignoreClientAddress
  );

  try {
    const {
      data: {
        cart: {
          payment_link: { url },
        },
      },
    } = yield call(api.post, `${getRole(roles)}/carts/${cart_id}/finalize`, {
      ignore_client_address: ignoreClientAddress,
      installments,
      payment_type: 111,
    });
    toast.success(link_rede ?? 'Link Gerado');
    toast.success(link_rede ? 'Link atualizado!' : 'Venda registrada');
    yield put(finishCartRedeSuccess({ url }));
    formRef.current.setFieldValue('link_rede', url);
  } catch (err) {
    yield put(apiError(err.message));
    toast.error(err.response.data.message);
  }
}

function* finishCartPagSeguro({ payload: { installments, cart_id, formRef } }) {
  const roles = yield select(({ user }) => user.profile.roles);
  const link_pagseguro = yield select(({ cart }) => cart.link_pagseguro);
  const ignoreClientAddress = yield select(
    ({ cart }) => cart.ignoreClientAddress
  );

  try {
    const {
      data: {
        cart: {
          payment_link: { url },
        },
      },
    } = yield call(api.post, `${getRole(roles)}/carts/${cart_id}/finalize`, {
      ignore_client_address: ignoreClientAddress,
      installments,
      payment_type: 113,
    });
    toast.success(link_pagseguro ?? 'Link Gerado');
    toast.success(link_pagseguro ? 'Link atualizado!' : 'Venda registrada');
    yield put(finishCartPagSeguroSuccess({ url }));
    formRef.current.setFieldValue('link_pagseguro', url);
  } catch (err) {
    yield put(apiError(err.message));
    toast.error(err.response.data.message);
  }
}

function* finishCart({ payload: payment_data }) {
  const roles = yield select(({ user }) => user.profile.roles);
  const ignoreClientAddress = yield select(
    ({ cart }) => cart.ignoreClientAddress
  );
  // const link_cielo = yield select(({ cart }) => cart.link_cielo);

  const { cart_id, payment_type, page_state } = payment_data;
  try {
    page_state.setLoadingQrCode(true);
    // page_state.setShowPixQrCodeModal(true);

    const {
      data: { cart },
    } = yield call(api.post, `${getRole(roles)}/carts/${cart_id}/finalize`, {
      payment_type,
      ignore_client_address: ignoreClientAddress,
    });

    const {
      payment_process: { qrcode },
    } = cart;

    page_state.setPixQrCode(qrcode);
    page_state.setLoadingQrCode(false);
    yield put(finishCartPixSuccess({ qrcode }));
  } catch (err) {
    const errors = err?.response?.data;
    if (errors.message)
      toast.error(errors.message, { autoClose: errors.duration });
    yield put(apiError(err.message));
  }
}

function* finishCartDelivery({
  payload: {
    data,
    cart_id,
    formRef,
    page_state,
    amount,
    nfce,
    display_payment,
    userFreight,
    giftback_amount,
  },
}) {
  const roles = yield select(({ user }) => user.profile.roles);
  try {
    const dataSend = {
      amount,
      display_payment,
      freight_user: userFreight,
      giftback_amount,
    };

    if (nfce) {
      dataSend.nfce = {
        number: nfce,
      };
    }

    yield call(api.patch, `${getRole(roles)}/carts/${cart_id}`, dataSend);

    yield call(api.post, `${getRole(roles)}/carts/${cart_id}/finalize`, data);

    // toast.success('Venda registrada');
    yield put(resetCart());
    history.push('/');
  } catch (err) {
    const errors = err?.response?.data;
    if (errors?.change)
      formRef?.current?.setFieldError('change', errors?.change);
    yield put(apiError(err.message));
    page_state?.setHandleDisabled(false);
  }
}

// function* updateDeliveryType({ payload: { productDelivery } }) {
//   try {
//     const roles = yield select(({ user }) => user.profile.roles);
//     const ignoreClientAddress = yield select(
//       ({ cart }) => cart.ignoreClientAddress
//     );
//     const Cart = yield select(({ cart }) => cart);

//     const useCustomDeliveryAddress =
//       productDelivery && ignoreClientAddress
//         ? ignoreClientAddress
//         : !Cart.useUserAddress;

//     let data = {
//       is_for_delivery: productDelivery,
//       use_custom_delivery_address: false,
//     };

//     if (!productDelivery) {
//       data = {
//         ...data,
//         freight: 0,
//         delivery_method: null,
//       };
//     }

//     if (!Cart.useUserAddress) data.delivery_address = Cart.address;

//     const {
//       data: { cart },
//     } = yield call(api.patch, `${getRole(roles)}/carts/${Cart.cart.id}`, data);

//     if (!productDelivery) {
//       yield put(saveCartStore(cart.store));
//     }

//     yield put(
//       updateDeliverySuccess({
//         productDelivery,
//         useCustomDeliveryAddress,
//         freight: null,
//       })
//     );
//   } catch (err) {
//     yield put(apiError(err.message));
//   }
// }

function* SetVehicleFleet({ payload }) {
  try {
    const roles = yield select(({ user }) => user.profile.roles);

    const Cart = yield select(({ cart }) => cart);

    const {
      data: { cart },
    } = yield call(api.patch, `${getRole(roles)}/carts/${Cart.cart.id}`, {
      delivery_vehicle: payload,
    });

    yield put(updateFreightVehicle(payload, cart));
  } catch (err) {
    yield put(apiError(err.message));
  }
}

function* SetDeliveryFleet({ payload: { fleet } }) {
  const roles = yield select(({ user }) => user.profile.roles);
  const Cart = yield select(({ cart }) => cart.cart);
  if (fleet === 'RETIRAR') {
    yield put(setDeliveryFleetSuccess(Cart, fleet));
    return;
  }

  try {
    const {
      data: { cart },
    } = yield call(api.patch, `${getRole(roles)}/carts/${Cart.id}`, {
      delivery_method: fleet,
    });
    yield put(setDeliveryFleetSuccess(cart, fleet));
  } catch (err) {
    yield put(apiError(err.message));
  }
}

function* updateCartInformation({
  payload: {
    discount,
    percentage_discount,
    observation,
    referral_code,
    totalPercentage,
    shareable,
    delivery_observation,
    is_suitcase_delivery,
    receipt_number,
    client_finish,
    target_user_id,
    setHandleDisabled,
  },
}) {
  const roles = yield select(({ user }) => user.profile.roles);
  const isShareable = yield select(({ cart }) => cart.shared);

  const client = yield select(({ cart }) => cart.client);
  const deliveryFleet = yield select(({ cart }) => cart.deliveryFleet);
  const isForDelivery = yield select(({ cart }) => cart.is_for_delivery);
  const vehicle = yield select(({ cart }) => cart.vehicle);
  const store = yield select(({ cart }) => cart.selectedStore);

  const cartInfo = {
    client_id: client ? client.id : null,
    store_id: store.value ?? null,
    delivery_observation,
    is_suitcase_delivery,
    amount: is_suitcase_delivery ? 0 : undefined,
    is_for_delivery: isForDelivery,
    target_user_id: target_user_id || '',
  };

  if (isForDelivery) {
    cartInfo.delivery_method = deliveryFleet;
    cartInfo.delivery_vehicle = vehicle;
  }

  if (receipt_number) {
    cartInfo.nfce = {
      number: receipt_number,
    };
  }

  if (client_finish) {
    cartInfo.client_finish = client_finish;
  }

  try {
    const Cart = yield select(({ cart }) => cart);
    const {
      data: { cart },
    } = yield call(
      api.patch,
      `${getRole(roles)}/carts/${Cart.cart.id}`,
      cartInfo
    );

    // toast.success('Carrinho atualizado!');

    yield put(
      updateCartInformationsSuccess({
        cart,
        amount: cart.amount,
        discount,
        percentage_discount,
        observation,
        referral_code,
        totalPercentage,
        shareable,
        delivery_observation,
        receipt_number,
      })
    );
    if (isShareable) {
      yield put(setShareableCartToken({ link: cart.shareable_token }));
    }

    // if (
    //   (client?.address?.has_coordinates && deliveryFleet === 'BEE') ||
    //   deliveryFleet !== 'BEE'
    // )
    yield put(nextStep());
    // toast.success('Carrinho editado!');
  } catch (err) {
    if (err.response?.data?.message) {
      toast.error(err.response?.data?.message);
    }
    yield put(apiError(err.message));

    if (err.response?.data?.referral_code)
      toast.error(err.response.data.referral_code[0]);

    if (err.response?.data?.amount) toast.error(err.response.data.amount[0]);
  } finally {
    setHandleDisabled(false);
  }
}

function* PatchCart({ payload: { data } }) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    const cart = yield select(({ cart: Cart }) => Cart.cart);

    const id = data.id || cart.id;

    yield call(api.patch, `${getRole(roles)}/carts/${id}`, {
      ...data,
    });

    // toast.success('Carrinho atualizado!');
    yield put(patchCartSuccess({ ...data }));
  } catch (err) {
    yield put(apiError(err.message));

    if (err.response?.data?.referral_code)
      toast.error(err.response.data.referral_code[0]);
  }
}

function* SaveShareableCart({
  payload: {
    // products,
    discount,
    percentage_discount,
    max_installments,
    delivery_method,
    // salesman_store,
    amount,
    receipt_number,
    client_finish,
  },
}) {
  const roles = yield select(({ user }) => user.profile.roles);

  const store_id = yield select(({ cart }) => cart.store_id);
  const order_number = yield select(({ cart }) => cart.order_number);
  const is_shareable = yield select(({ cart }) => cart.shared);

  try {
    const cartInfo = {
      amount,
      order_number,
      store_id,
      is_shareable,
      max_installments,
      delivery_method,
      is_for_delivery: false,
    };

    if (receipt_number) {
      cartInfo.nfce = {
        number: receipt_number,
      };
    }

    if (client_finish) {
      cartInfo.client_finish = client_finish;
    }

    const {
      data: { cart },
    } = yield call(api.post, `${getRole(roles)}/carts`, cartInfo);

    yield put(
      patchCart({
        id: cart.id,
        discount,
        percentage_discount,
        max_installments,
        receipt_number,
      })
    );

    // if (!salesman_store) {
    //   yield call(api.post, `${getRole(roles)}/carts/${cart.id}/products`, {
    //     products,
    //   });
    // }

    // yield put(setShowShareableCartModal(true));
    yield put(saveShareableCartSuccess(cart));
    // if (salesman_store) {
    yield put(nextStep());
    // }
    yield put(setShareableCartToken({ link: cart.shareable_token }));
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
}

function* finishCartLink({
  payload: {
    max_installments,
    cart_id,
    is_shareable,
    is_payment_checkout,
    payment_type,
    display_payment,
    freight_user,
    page_state,
    amount,
    nfce,
    giftback_amount,
  },
}) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    if (
      payment_type === 105 ||
      payment_type === 106 ||
      payment_type === 110 ||
      payment_type === 112 ||
      payment_type === 114
    ) {
      page_state?.setLoadingQrCode(true);

      const dataSend = {
        max_installments,
        is_shareable,
        is_payment_checkout,
        amount,
        payment_type,
        display_payment,
        freight_user,
        giftback_amount,
      };

      if (nfce) {
        dataSend.nfce = {
          number: nfce,
        };
      }

      const {
        data: { cart },
      } = yield call(api.patch, `${getRole(roles)}/carts/${cart_id}`, dataSend);

      yield put(
        setShareableCartToken({
          link: cart.shareable_token,
          qrcode: cart.payment_process.qrcode,
        })
      );
      page_state?.setPixQrCode(cart.payment_process.qrcode);
      page_state?.setLoadingQrCode(false);
      page_state?.setHandleDisabled(false);
    } else {
      const {
        data: { cart },
      } = yield call(api.patch, `${getRole(roles)}/carts/${cart_id}`, {
        max_installments,
        is_shareable,
        is_payment_checkout,
        amount,
        payment_type,
        display_payment,
        freight_user,
        giftback_amount,
      });

      yield put(setShareableCartToken({ link: cart.shareable_token }));
      page_state?.setHandleDisabled(false);
    }
  } catch (err) {
    yield put(apiError(err.message));
    toast.error(err?.response?.data?.message);
    page_state?.setHandleDisabled(false);
    page_state?.setHandleDisabledGenerate(false);
  }
}

function* saveCustomAddress({ payload: { address, useUserAddress } }) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    const Cart = yield select(({ cart }) => cart);
    const showRegionsModal = select(({ cart }) => cart.showRegionsModal);
    const data = {
      use_custom_delivery_address: !useUserAddress,
      delivery_address: address,
    };

    const {
      data: {
        cart: { delivery_address },
      },
    } = yield call(api.patch, `${getRole(roles)}/carts/${Cart.cart.id}`, data);

    yield put(saveCustomDeliveryAddressSuccess(delivery_address));
    yield put(submitRegionSuccess());
    toast.success('Endereço confirmado com sucesso!');
    if (showRegionsModal) {
      yield put(setShowRegionsModal(false));
    }
  } catch (err) {
    yield put(apiError(err.message));

    if (err?.response) {
      toast.error(err?.response?.data?.message);
      // if (err.response?.status === 500) {
      //   yield put(handleAddressNotFound());
      // }
    }
  }
}

function* updateCustomAddress({ payload: { useUserAddress } }) {
  const roles = yield select(({ user }) => user.profile.roles);
  const chosenRegion = yield select(({ cart }) => cart.chosenRegion);

  try {
    const Cart = yield select(({ cart }) => cart);
    const data = {
      use_custom_delivery_address: !useUserAddress,
    };

    if (Cart.address && !useUserAddress) data.address = Cart.address;

    if (useUserAddress) {
      yield call(api.patch, `${getRole(roles)}/carts/${Cart.cart.id}`, {
        use_custom_delivery_address: false,
      });
    }

    yield put(UpdateCustomDeliverySuccess(useUserAddress));
    if (chosenRegion) {
      yield put(setChosenRegion(null));
    }
  } catch (err) {
    yield put(apiError(err.message));
  }
}

function* updateClientAddress({ payload: { address } }) {
  const roles = yield select(({ user }) => user.profile.roles);

  try {
    const { id, name, telephone } = yield select(({ cart }) => cart.client);

    yield call(api.put, `${getRole(roles)}/clients/${id}`, {
      name,
      telephone,
      address,
    });

    yield put(
      updateClientAddressSuccess({
        address,
      })
    );
  } catch (err) {
    yield put(apiError(err.message));

    if (err?.response) {
      toast.error(err?.response?.data?.message);
    }
  }
}

function* saveClientAddress({ payload: { address } }) {
  const roles = yield select(({ user }) => user.profile.roles);
  const deliveryFleet = yield select(({ cart }) => cart.deliveryFleet);

  try {
    const { id, name, telephone } = yield select(({ cart }) => cart.client);

    const {
      data: { client },
    } = yield call(api.put, `${getRole(roles)}/clients/${id}`, {
      name,
      telephone,
      address,
    });

    yield put(saveClientAddressSuccess(client.address));

    if (!client.address.has_coordinates && deliveryFleet === 'BEE') {
      yield put(handleAddressNotFound(false));
    } else {
      yield put(nextStep());
    }
  } catch (err) {
    yield put(apiError(err.message));

    if (err?.response) {
      toast.error(err?.response?.data?.message);
    }
  }
}

function* HandleAddressNotFound({ payload: { isShared } }) {
  try {
    const roles = yield select(({ user }) => user.profile.roles);
    const storeId = yield select(({ cart }) => cart?.selectedStore?.value);
    const sharedCartStoreId = yield select(
      ({ shareable_cart }) => shareable_cart?.cart?.store?.id
    );

    const store_id = isShared ? sharedCartStoreId : storeId;

    yield put(setLoadingRegions(true));
    yield put(setShowRegionsModal(true));
    const {
      data: { regions },
    } = yield call(
      api.get,
      `/${getRole(roles)}/stores/${store_id}/delivery/regions`
    );
    yield put(setStoreRegions(regions));

    yield put(setLoadingRegions(false));
  } catch (err) {
    if (err?.response) {
      toast.error(err?.response?.data?.message);
      if (err.response?.status === 500) {
        toast.error('Nenhuma região foi encontrada...');
        yield put(setLoadingRegions(false));
        yield put(setShowRegionsModal(false));
        // yield put(handleAddressNotFound());
      }
    }
    // yield put(apiError(err.message));
  }
}

function* SubmitRegion({ payload: isShared }) {
  try {
    const chosenRegion = yield select(({ cart: Cart }) => Cart.chosenRegion);

    if (!isShared) {
      const roles = yield select(({ user }) => user.profile.roles);
      const cart = yield select(({ cart: Cart }) => Cart.cart);
      const deliveryAddress = yield select(
        ({ cart: Cart }) => Cart.cart.delivery_address
      );
      const address = {
        use_custom_delivery_address: true,
        delivery_address: {
          ...deliveryAddress,
          delivery_additional_data: {
            region_id: chosenRegion.id,
            name: chosenRegion.name,
          },
        },
      };

      yield call(api.patch, `${getRole(roles)}/carts/${cart.id}`, address);
    } else {
      const cart = yield select(
        ({ shareable_cart: Shareable_Cart }) => Shareable_Cart.cart
      );
      const deliveryAddress = cart.delivery_address;

      const address = {
        use_custom_delivery_address: true,
        delivery_address: {
          ...deliveryAddress,
          delivery_additional_data: {
            region_id: chosenRegion.id,
            name: chosenRegion.name,
          },
        },
      };

      yield put(patchShareableCart(address));
    }

    // yield put(saveCustomDeliverSyAddressSuccess(address));

    yield put(setLoadingRegions(false));
    yield put(setShowConfirmRegionModal(false));

    // submit();
    yield put(submitRegionSuccess());
    // toast.success('Endereço confirmado com sucesso!');
  } catch (err) {
    yield put(apiError(err.message));
    if (err?.response) {
      toast.error(err?.response?.data?.message);
      if (err.response?.status === 500) {
        toast.error('Nenhuma região foi encontrada...');
        yield put(setLoadingRegions(false));
        yield put(setShowRegionsModal(false));
        // yield put(handleAddressNotFound());
      }
    }
    // yield put(apiError(err.message));
  }
}

export default all([
  takeLatest(GENERATE_ORDER_NUMBER, getGeneratedOrderNumber),
  takeLatest(LOAD_CART, loadCart),
  takeLatest(CREATE_CART, CreateCart),
  takeLatest(CREATE_CLIENT, createClient),
  takeLatest(SAVE_SHAREABLE_CART, SaveShareableCart),
  takeLatest(SAVE_CLIENT_ADDRESS, saveClientAddress),
  takeLatest(SAVE_INFORMATIONS_USER, saveInformationsUser),
  takeLatest(ADD_PRODUCT, addProduct),
  takeLatest(REMOVE_PRODUCT, removeProduct),
  takeLatest(UPDATE_PRODUCT, updateProduct),
  takeLatest(FINISH_CART_LINK, finishCartLink),
  takeLatest(FINISH_CART_CIELO, finishCartCielo),
  takeLatest(FINISH_CART_PAGARME, finishCartPagarMe),
  takeLatest(FINISH_CART_MERCADOPAGO, finishCartMercadoPago),
  takeLatest(FINISH_CART_REDE, finishCartRede),
  takeLatest(FINISH_CART_PAGSEGURO, finishCartPagSeguro),
  takeLatest(FINISH_CART, finishCart),
  takeLatest(FINISH_CART_DELIVERY, finishCartDelivery),
  // takeLatest(UPDATE_DELIVERY, updateDeliveryType),
  takeLatest(SAVE_CUSTOM_DELIVERY_ADDRESS, saveCustomAddress),
  takeLatest(UPDATE_USE_CUSTOM_DELIVERY_ADDRESS, updateCustomAddress),
  takeLatest(UPDATE_CART_INFORMATIONS, updateCartInformation),
  takeLatest(HANDLE_ADDRESS_NOT_FOUND, HandleAddressNotFound),
  takeLatest(SUBMIT_REGION, SubmitRegion),
  takeLatest(SET_VEHICLE_FLEET, SetVehicleFleet),
  takeLatest(SET_DELIVERY_FLEET_REQUEST, SetDeliveryFleet),
  takeLatest(PATCH_CART, PatchCart),
  takeLatest(SET_IGNORE_CLIENT_ADDRESS, IgnoreClientAddressAsyncSideEffects),
  takeLatest(UPDATE_CLIENT_ADDRESS, updateClientAddress),
]);
