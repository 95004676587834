export const translatePaymentWay = (
  payment_type,
  is_shareable,
  checkout,
  hasPaymentProcess
) => {
  if (is_shareable && !checkout && !payment_type) {
    return {
      detailed: 'Carrinho Compartilhável',
      not_detailed: 'Checkout Transparente',
    };
  }
  if (is_shareable && checkout && !hasPaymentProcess && payment_type === 0) {
    return {
      detailed: 'Carrinho Compartilhável',
      not_detailed: 'Checkout Transparente',
    };
  }
  if (payment_type === 101)
    return {
      detailed: 'Carrinho Compartilhável',
      not_detailed: 'Checkout Transparente',
    };

  if (payment_type === 102)
    return {
      detailed: 'Transferência bancária',
      not_detailed: 'Transferência bancária',
    };

  if (payment_type === 103)
    return {
      detailed: 'Carrinho Compartilhável',
      not_detailed: 'Cielo',
    };

  if (payment_type === 104)
    return {
      detailed: 'Carrinho Compartilhável',
      not_detailed: 'Cielo',
    };

  if (
    payment_type === 105 ||
    payment_type === 106 ||
    payment_type === 110 ||
    payment_type === 112 ||
    payment_type === 114
  )
    return {
      detailed: 'Transferência por PIX',
      not_detailed: 'PIX',
    };

  if (payment_type === 107)
    return {
      detailed: 'Link da Pagar.me',
      not_detailed: 'Link de Pagamento',
    };

  if (payment_type === 108)
    return {
      detailed: 'Carrinho Compartilhável',
      not_detailed: 'Pagar.me',
    };

  if (payment_type === 109)
    return {
      detailed: 'Carrinho Compartilhável',
      not_detailed: 'Mercado Pago',
    };

  if (payment_type === 111)
    return {
      detailed: 'Carrinho Compartilhável',
      not_detailed: 'Rede',
    };

  if (payment_type === 113)
    return {
      detailed: 'Carrinho Compartilhável',
      not_detailed: 'PagSeguro',
    };

  if (payment_type === 201)
    return {
      detailed: 'Maquineta (Crédito)',
      not_detailed: 'Cartão de crédito',
    };

  if (payment_type === 202)
    return {
      detailed: 'Maquineta (Débito)',
      not_detailed: 'Cartão de débito',
    };

  if (payment_type === 203)
    return {
      detailed: 'Dinheiro',
      not_detailed: 'Dinheiro',
    };

  if (payment_type === 301)
    return {
      detailed: 'Link da Cielo e Cartão de Crédito',
      not_detailed: 'Duas formas de pagamento',
    };

  if (payment_type === 302)
    return {
      detailed: 'Link da Cielo e Cartão de Débito',
      not_detailed: 'Duas formas de pagamento',
    };

  if (payment_type === 303)
    return {
      detailed: 'Link da Cielo e Dinheiro',
      not_detailed: 'Duas formas de pagamento',
    };

  if (payment_type === 305)
    return {
      detailed: 'Cartão de Crédito e Dinheiro',
      not_detailed: 'Duas formas de pagamento',
    };

  if (payment_type === 306)
    return {
      detailed: 'Dois Cartões de Crédito',
      not_detailed: 'Duas formas de pagamento',
    };

  if (payment_type === 307)
    return {
      detailed: 'Dois Cartões de Débito',
      not_detailed: 'Duas formas de pagamento',
    };

  if (payment_type === 308)
    return {
      detailed: 'Cartões de Crédito e Débito',
      not_detailed: 'Duas formas de pagamento',
    };

  if (payment_type === 309)
    return {
      detailed: 'Cartão de Débito e Dinheiro',
      not_detailed: 'Duas formas de pagamento',
    };

  if (payment_type === 401)
    return {
      detailed: 'Pago',
      not_detailed: 'Pago',
    };

  return null;
};

// eslint-disable-next-line consistent-return
export const definePaymentWay = (paymentModel, option) => {
  if (paymentModel === 'now') {
    if (option === '1' || option === '2') {
      return 101;
    }
    // if (option === '3') {
    //   return 102;
    // }
    if (option === '4') {
      return 105;
    }
  }
  if (paymentModel === 'later') {
    return Number(`20${option}`);
    // option = 1, 2 or 3
  }
  if (paymentModel === 'mixed') {
    return Number(`30${option}`);
    // option = 1, 2, 3, 4, 5, 6, 7, 8, 9
  }
};

export const getPaymentValue = payment => {
  const isMultipleMethods = payment.payment_type > 300;

  if (payment) {
    if (isMultipleMethods && payment.first_payment?.installments > 1) {
      return payment.first_payment?.installments;
    }
    if (payment.installments > 1) {
      return payment.installments;
    }
    if (payment.max_installments > 1) {
      return payment.max_installments;
    }
  }

  return 'Pagamento à vista';
};

export const paymentTypes = [
  { value: 0, label: 'Não definido' },

  { value: 101, label: 'Link da Cielo' },

  { value: 102, label: 'Transferência bancária' },

  { value: 103, label: 'Carrinho Compartilhável - Cielo' },

  { value: 104, label: 'Checkout Transparente da Cielo - Débito' },

  { value: 105, label: 'Pix GerênciaNET' },

  { value: 106, label: 'Pix Pagar.me' },

  { value: 107, label: 'Link da Pagar.me' },

  { value: 108, label: 'Carrinho Compartilhável - Pagar.me' },

  { value: 109, label: 'Carrinho Compartilhável - Mercado Pago' },

  { value: 111, label: 'Carrinho Compartilhável - Rede' },

  { value: 112, label: 'Pix Cielo' },

  { value: 201, label: 'Maquineta (Crédito)' },

  { value: 202, label: 'Maquineta (Débito)' },

  { value: 203, label: 'Dinheiro' },

  { value: 301, label: 'Cielo e Cartão de Crédito' },

  { value: 302, label: 'Cielo e Cartão de Débito' },

  { value: 303, label: 'Cielo e Dinheiro Vivo' },

  { value: 304, label: 'Transferência Bancária e Cartão de Crédito' },

  { value: 305, label: 'Cartão de Crédito e Dinheiro' },

  { value: 306, label: 'Dois Cartões de Crédito' },

  { value: 307, label: 'Dois Cartões de Débito' },

  { value: 308, label: 'Cartão de Crédito e Cartão de Débito' },

  { value: 309, label: 'Cartão de Débito e Dinheiro' },

  { value: 401, label: 'Pago' },
];
