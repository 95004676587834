import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Digite o nome')
    .max(255, 'Digite no máximo 255 caracteres'),
  code: Yup.string()
    .required('Digite o código')
    .max(255, 'Digite no máximo 255 caracteres'),
  // bar_code: Yup.string().required('Digite o código de barras'),
  value: Yup.number()
    .required('Digite o valor do produto')
    .min(0.01, 'Digite o valor do produto')
    .typeError('Digite o valor do produto'),
});

export default schema;
