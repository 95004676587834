/* eslint-disable consistent-return */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Col, CustomInput, Row } from 'reactstrap';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import history from '~/services/history';

import Pagination from '~/components/Pagination';

import api from '~/services/api';
import SearchBox from '~/components/SearchBox';
import DropdownFilter from '~/components/DropdownFilter';
import DeleteModal from '~/components/Modals/DeleteModal';
import { breakString } from '~/util/newLayoutHelpers';

import { deliveryStatuses, salesStatuses } from '~/util/filters';
import Loading from '~/components/Loading';
// import InputSelectAsyncFilter from '~/components/InputSelectAsyncFilter';
import { paymentTypes } from '~/util/translatePaymentWay';
import InputMaskFilter from '~/components/InputMaskFilter';
import { getRole } from '~/util/getRole';
// import TextFilter from '~/components/TextFilter';
import useRoles from '~/hooks/useRoles';
import { setSearch } from '~/store/modules/search/actions';
import SaleInfoCard from './SaleInfoCard';

const SalesList = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const mountedRef = useRef(true);

  const [sales, setSales] = useState(null);
  const subscriber = useSelector(
    ({ user }) => user?.profile?.subscriber?.fantasy_name
  );
  const searchQuery = useSelector(({ search }) => search.query);
  const [allSales, setAllSales] = useState(false);
  const [selectedSales, setSelectedSales] = useState([]);
  const [filters, setFilters] = useState(null);
  const [paymentFilter, setPaymentFilter] = useState(null);
  const [deliveryFilter, setDeliveryFilter] = useState(null);
  const [statusFiltered, setStatusFiltered] = useState(null);
  const [statusFilter, setStatusFilter] = useState(state?.salesStatus || null);
  // const [clientFilter, ] = useState(null);
  // const [storeFilter, ] = useState(null);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState(null);
  const [deliveryFiltered, setDeliveryFiltered] = useState();
  const [createdAtFilter, setCreatedAtFilter] = useState(null);
  const [pagination, setPagination] = useState(
    { current_page: state?.page } || null
  );
  const [loading, setLoading] = useState(true);
  // const [storeQuery, setStoreQuery] = useState('');

  const [saleToBeDeleted, setSaleToBeDeleted] = useState(null);
  const [showDeleteSaleModal, setShowDeleteSaleModal] = useState(false);
  const width = window.innerWidth;

  const statusText = useMemo(() => {
    const statusObj = salesStatuses.find(s => s.value === statusFilter);
    if (statusObj?.value === 13) {
      return statusObj?.name;
    }
    if (statusObj?.value === 0 || statusObj?.value === 1) {
      return;
    }
    return statusObj?.label;
  }, [statusFilter]);

  const roles = useSelector(({ user }) => user.profile.roles);

  const role = useMemo(
    () =>
      getRole(roles, [
        'administrator',
        'digital-consultant',
        'salesman',
        'store-manager',
        'financier',
      ]),
    [roles]
  );

  const { isSalesman, isConsultant, isAdmin } = useRoles();

  const showSellerInfo = (!isSalesman && !isConsultant) || isAdmin;

  const getURL = useMemo(() => {
    if (statusFilter === 12) {
      return `${role}/carts?status[]=0&status[]=1&status[]=2&status[]=3`;
    }
    return `${role}/carts`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, role]);

  // const getSalesmansURL = useMemo(() => {
  //   if (isAdmin) {
  //     return '/administrator/users';
  //   }
  //   if (isStoreManager) {
  //     return '/store-manager/users';
  //   }
  //   if (isSalesman) {
  //     return '/salesman/users/coworkers';
  //   }
  //   return null;
  // }, [isStoreManager, isAdmin, isSalesman]);

  const deleteURL = useMemo(() => {
    return `${role}/carts/${saleToBeDeleted?.id}`;
  }, [role, saleToBeDeleted]);

  const params = useMemo(() => {
    return {
      query: searchQuery || null,
      status: statusFilter < 7 ? statusFilter : null,
      status_text:
        statusFilter === 0 ||
        statusFilter === 1 ||
        (statusFilter >= 7 && statusFilter < 12) ||
        statusFilter >= 13
          ? statusText
          : null,
      /* client:
        (clientFilter !== null && clientFilter.startsWith('não')
          ? 'não informado'
          : clientFilter) || null,
      store: storeFilter || null, */
      payment_type: paymentTypeFilter,
      created_at: createdAtFilter,
      delivery_method: deliveryFilter,
      // updated_at: updatedAtFilter,
      page:
        state?.page || pagination?.current_page > 1
          ? pagination?.current_page
          : null,
    };
  }, [
    statusFilter,
    pagination,
    statusText,
    searchQuery,
    // clientFilter,
    // storeFilter,
    paymentTypeFilter,
    createdAtFilter,
    deliveryFilter,
    state,
  ]);

  const setData = data => {
    if (data) {
      const carts = data.carts.map(cart => ({
        ...cart,
        formatted_updated_at: format(
          parseISO(cart.updated_at),
          "dd/MMM/yy - HH'h'mm",
          {
            locale: ptBR,
          }
        ),
        client: {
          ...cart.client,
          broken_name: cart.client
            ? breakString(cart.client?.name, ' ', 18)
            : null,
        },
        seller: {
          ...cart.created_by,
          broken_name: cart.created_by
            ? breakString(cart.created_by?.name, ' ', 18)
            : null,
        },
        handled: {
          ...cart.handled_by,
          broken_name: cart.handled_by
            ? breakString(cart.handled_by?.name, ' ', 18)
            : null,
        },
        store: {
          ...cart.store,
          broken_fantasy_name: breakString(
            cart.store?.fantasy_name,
            ' ',
            18,
            true
          ),
        },
      }));
      setSales(carts);
      setPagination(data.meta);
      setFilters(data.filters);
    }
  };

  const loadSales = useCallback(async () => {
    if (!mountedRef.current) return null;

    const { data } = await api.get(getURL, {
      params: {
        ...params,
        query: params.query || '',
      },
    });

    if (!mountedRef.current) return null;

    setData(data);
    history.replace();
  }, [params, getURL]);

  useEffect(() => {
    const refreshInterval = setInterval(loadSales, 40 * 1000);

    return () => clearInterval(refreshInterval);
  }, [loadSales, sales]);

  useEffect(() => {
    dispatch(setSearch({ query: '' }));

    return () => {
      mountedRef.current = false;
    };
  }, [dispatch]);

  // useEffect(() => {
  //   let interval = null;
  //   if (!loading) {
  //     interval = setInterval(
  //       async () => {
  //         const { data } = await api.get(getURL, { params });
  //         if (
  //           componentReqRunningRef.current === false &&
  //           letIntervalRef.current === true
  //         ) {
  //           setData(data);
  //         } else if (
  //           componentReqRunningRef.current === false &&
  //           letIntervalRef.current === false
  //         ) {
  //           setLetInterval(true);
  //         }
  //       },
  //       5 * 1000,
  //       []
  //     );
  //   } else {
  //     clearInterval(interval);
  //   }

  //   return () => {
  //     clearInterval(interval);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loading, getURL, params]);

  const handleSelectOrUnselectSale = (sale, isSelected) => {
    if (isSelected) {
      setSelectedSales(selectedSales.filter(s => s.id !== sale.id));
    } else {
      setSelectedSales([...selectedSales, sale]);
    }
  };

  useEffect(() => {
    if (allSales) {
      setSelectedSales(sales);
    } else {
      setSelectedSales([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSales]);

  useEffect(() => {
    if (filters !== null) {
      const getPaymentTypes = paymentTypes.filter(types =>
        filters.payment_types.includes(types.value)
      );
      const getSituationTypes = salesStatuses.filter(types =>
        filters?.status.includes(types.value)
      );

      const getDeliveryTypes = deliveryStatuses.filter(types =>
        filters.delivery_methods.includes(types.value)
      );

      const getDeliveryTypesWithSubscriber = getDeliveryTypes.map(type =>
        type.value === 'PROPRIA' ? { ...type, label: `Alternativa` } : type
      );

      setDeliveryFiltered(getDeliveryTypesWithSubscriber);
      setPaymentFilter(getPaymentTypes);
      setStatusFiltered(getSituationTypes);
    }
  }, [filters, subscriber]);

  return (
    <>
      <Row className="w-100 mx-0 mb-4 d-block d-xl-none">
        <Col className="px-0 mt-3 pt-1 mb-0 my-lg-0" xs={12} xl={6}>
          <SearchBox
            url={getURL}
            params={params}
            query={searchQuery}
            setQuery={q => dispatch(setSearch({ query: q }))}
            setData={setData}
            setLoading={setLoading}
          />
        </Col>
      </Row>
      <Row className="w-100 mx-0 mb-2 mb-lg-4 d-flex flex-row align-items-center filters">
        <span className="text-left mt-0 mb-2 mb-lg-0 text-muted font-size-14 mr-2 filter-text-desktop">
          Filtros
        </span>
        <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
          <DropdownFilter
            name="sales-status-filter"
            id="sales-status-filter"
            placeholder="Situação"
            options={statusFiltered}
            url={getURL}
            params={params}
            setData={setData}
            setLoading={setLoading}
            filter={statusFilter}
            setFilter={setStatusFilter}
            query={searchQuery}
            setQuery={q => dispatch(setSearch({ query: q }))}
            clearable
            width={width > 399 ? 180 : 340}
            menuListWidth={width > 399 ? 250 : 340}
          />
        </Col>
        <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
          <DropdownFilter
            name="payment-type-filter"
            id="payment-type-filter"
            placeholder="Tipo de Pagamento"
            options={paymentFilter}
            url={getURL}
            params={params}
            setData={setData}
            setLoading={setLoading}
            filter={paymentTypeFilter}
            setFilter={setPaymentTypeFilter}
            query={searchQuery}
            setQuery={q => dispatch(setSearch({ query: q }))}
            clearable
            width={width > 399 ? 180 : 340}
            menuListWidth={width > 399 ? 250 : 340}
          />
        </Col>

        <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
          <DropdownFilter
            name="delivery-type-filter"
            id="delivery-type-filter"
            placeholder="Tipo de Entrega"
            options={deliveryFiltered}
            url={getURL}
            params={params}
            setData={setData}
            setLoading={setLoading}
            filter={deliveryFilter}
            setFilter={setDeliveryFilter}
            query={searchQuery}
            setQuery={q => dispatch(setSearch({ query: q }))}
            clearable
            width={width > 399 ? 180 : 340}
            menuListWidth={width > 399 ? 250 : 340}
          />
        </Col>

        {/* <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
          <TextFilter
            name="client-filter"
            id="client-filter"
            itemsname="clients"
            placeholder="Cliente"
            url={getURL}
            params={params}
            query={clientFilter}
            setQuery={setClientFilter}
            setLoading={setLoading}
            setData={setData}
          />
        </Col>
        <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
          <TextFilter
            name="sales-store-filter"
            id="sales-store-filter"
            itemsname="stores"
            placeholder="Loja"
            url={getURL}
            params={params}
            query={storeFilter}
            setQuery={setStoreFilter}
            setLoading={setLoading}
            setData={setData}
          />
          <Input
            placeholder="Loja"
            value={storeQuery}
            onChange={({ target: { value } }) => setStoreQuery(value)}
            className="store-in-page-filter"
          />
          <DropdownFilter
            name="sales-store-filter"
            id="sales-store-filter"
            placeholder="Loja"
            options={userStores}
            url={getURL}
            params={params}
            setData={setData}
            setLoading={setLoading}
            filter={storeFilter}
            setFilter={setStoreFilter}
            query={searchQuery}
            setQuery={setSearchQuery}
            setReqRunning={setComponentReqRunning}
            clearable
            width={100}
            menuListWidth={width > 399 ? 250 : 340}
          />
        </Col> */}

        <Col xs="auto" className="pl-0 pr-2 mb-2 mb-lg-0">
          <InputMaskFilter
            name="created_at-filter"
            id="created_at-filter"
            isDateType
            placeholder="Data"
            mask="99/99/9999"
            generateFilterParams={date => ({ created_at: date })}
            params={params}
            url={getURL}
            setLoading={setLoading}
            setData={setData}
            setFilter={setCreatedAtFilter}
            filter={createdAtFilter}
            style={width > 399 ? { width: '180px' } : { width: '340px' }}
          />
        </Col>

        <Col
          xs="auto"
          className="ml-auto d-flex flex-column justify-items-end px-0"
        >
          <Button
            className="new-layout-btn main"
            color="primary"
            size="lg"
            onClick={() => history.push('/registrar')}
          >
            <i className="las la-plus font-size-16 align-middle mr-2" />
            Inserir Venda
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <>
          {sales?.length ? (
            <>
              <Row className="w-100 mx-0 mb-4 d-none d-lg-flex  px-3">
                <Col xs={1} align="center" className="d-none">
                  <CustomInput
                    type="checkbox"
                    id="allSales"
                    className="text-primary z-index-0"
                    checked={allSales}
                    defaultValue={allSales}
                    onChange={() => setAllSales(!allSales)}
                  />
                </Col>
                <Col xs={showSellerInfo ? 1 : 2} align="start">
                  <div className="new-layout-table-column-label">Pedido</div>
                </Col>
                {showSellerInfo && (
                  <Col
                    xs={2}
                    align="start"
                    // style={{ paddingLeft: '1.25rem' }}
                  >
                    <div className="new-layout-table-column-label">
                      Gerenciado Por
                    </div>
                  </Col>
                )}
                <Col
                  xs={1}
                  align="start"
                  // style={{ paddingLeft: '1.25rem' }}
                >
                  <div className="new-layout-table-column-label">Cliente</div>
                </Col>
                <Col xs={2} align="start">
                  <div className="new-layout-table-column-label">Loja</div>
                </Col>
                <Col xs={1} align="start">
                  <div className="new-layout-table-column-label">Valor</div>
                </Col>
                <Col xs={2} align="start">
                  <div className="new-layout-table-column-label d-flex flex-row flex-wrap w-100">
                    Últ. Atualização
                  </div>
                </Col>
                <Col xs={showSellerInfo ? 2 : 3} align="center">
                  <div className="new-layout-table-column-label">Situação</div>
                </Col>
                <Col xs={1} align="center">
                  <div className="new-layout-table-column-label">Ações</div>
                </Col>
              </Row>

              {sales
                ?.filter(
                  sale => sale?.store.fantasy_name.toLowerCase()
                  // .includes(storeQuery.toLowerCase())
                )
                .map(sale => {
                  const isSelected = selectedSales.some(s => s.id === sale.id);
                  return (
                    <SaleInfoCard
                      key={sale.id}
                      sale={sale}
                      isSelected={isSelected}
                      onSelect={handleSelectOrUnselectSale}
                      onDelete={incomingSale => {
                        setSaleToBeDeleted(incomingSale);
                        setShowDeleteSaleModal(true);
                      }}
                      showInfoSeller={showSellerInfo}
                      params={params}
                    />
                  );
                })}

              <Row className="w-100 mx-0 mb-4">
                <Col className="px-0" align="end">
                  {pagination && (
                    <div className="mb-3">
                      <Pagination
                        paginationMeta={pagination}
                        url={getURL}
                        setData={setData}
                        setLoading={setLoading}
                        query={searchQuery}
                        params={params}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <h3 style={{ minHeight: '700px' }}>Nenhuma venda encontrada.</h3>
          )}
        </>
      )}
      <DeleteModal
        show={showDeleteSaleModal}
        setShow={setShowDeleteSaleModal}
        deleteUrl={deleteURL}
        getUrl={getURL}
        params={params}
        setData={setData}
        itemsArray={sales}
        pagination={pagination}
        query={searchQuery}
        setItemToBeDeleted={setSaleToBeDeleted}
        headerText="Deseja mesmo excluir a venda?"
        bodyText="Essa ação irá excluir a venda"
        itemName={`#${saleToBeDeleted?.order_number}`}
        successMsg="Venda excluída com sucesso!"
      />
    </>
  );
};

export default SalesList;
